import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, useLocation } from 'react-router-dom';
import NavBar from './Layout/NavBar';
import Homepage from './Homepage/Homepage';
import Flight from './Flights/Flights'
import Hotel from './Hotel/Hotel'
import Holidays from './Holidays/Packages'
import Contact from './Layout/Contact'
import Footer from './Layout/Footer'
import './App.scss';
import GoaPD1 from './Holidays/Goa/GoaPD1';
import GoaPD2 from './Holidays/Goa/GoaPD2';
import KeralaPD1 from './Holidays/Kerala/KeralaPD1';
import KeralaPD2 from './Holidays/Kerala/KeralaPD2';
import Bus from './Bus/Bus';
import Visa from './Visa/Visa';
import schengen from './Visa/Checklist/schengen';
import aus from './Visa/Checklist/aus';
import bgd from './Visa/Checklist/bgd';
import bgr from './Visa/Checklist/bgr';
import can from './Visa/Checklist/can';
import chn from './Visa/Checklist/chn';
import hrv from './Visa/Checklist/hrv';
import egy from './Visa/Checklist/egy';
import geo from './Visa/Checklist/geo';
import idn from './Visa/Checklist/idn';
import isr from './Visa/Checklist/isr';
import jpn from './Visa/Checklist/jpn';
import mys from './Visa/Checklist/mys';
import mar from './Visa/Checklist/mar';
import moz from './Visa/Checklist/moz';
import mmr from './Visa/Checklist/mmr';
import nzl from './Visa/Checklist/nzl';
import phl from './Visa/Checklist/phl';
import rus from './Visa/Checklist/rus';
import sgp from './Visa/Checklist/sgp';
import tha from './Visa/Checklist/tha';
import tur from './Visa/Checklist/tur';
import gbr from './Visa/Checklist/gbr';
import usa from './Visa/Checklist/usa';
import Passport from './Others/Passport';
import Others from './Others/Others';
import Insurance from './Others/Insurance';
import Car from './Others/Car';
import Railway from './Others/Railway';
import Form from './Layout/Form';
import Goa from './Holidays/Goa/Goa';
import Kerala from './Holidays/Kerala/Kerala';
import Sou from './Holidays/SOU/Sou';
import SouPD1 from './Holidays/SOU/SouPD1';
import SouPD2 from './Holidays/SOU/SouPD2';
import Saputara from './Holidays/Saputara/Saputara';
import SDK from './Holidays/Saputara/SDK';
import Orsang from './Holidays/Saputara/Orsang';
import std from './Visa/Checklist/std';
import About from './Homepage/About';
import Thailand from './Holidays/Thailand/Thailand';
import And from './Holidays/Andaman/And';
import andPD1 from './Holidays/Andaman/andPD1';
import andPD2 from './Holidays/Andaman/andPD2';
import andPD3 from './Holidays/Andaman/andPD3';
import CST from './Holidays/Saputara/CST';
import CDY from './Holidays/Saputara/CDY';
import Himachal from './Holidays/Saputara/Himachal';
import Euro from './Holidays/Saputara/Euro';
import Privacy from './Layout/Privacy';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const App = () => {
  return (
    <>
      <NavBar />
      <Router onUpdate={() => window.scrollTo(0, 0)}>
        <ScrollToTop />
        <Route exact path='/' component={Homepage} />
        <Route exact path={`${process.env.PUBLIC_URL}/flights`} component={Flight} />
        <Route exact path={`${process.env.PUBLIC_URL}/hotel`} component={Hotel} />
        <Route exact path={`${process.env.PUBLIC_URL}/holidays`} component={Holidays} />
        <Route exact path={`${process.env.PUBLIC_URL}/bus`} component={Bus} />
        <Route exact path={`${process.env.PUBLIC_URL}/visa`} component={Visa} />
        <Route exact path={`${process.env.PUBLIC_URL}/aus`} component={aus} />
        <Route exact path={`${process.env.PUBLIC_URL}/bgd`} component={bgd} />
        <Route exact path={`${process.env.PUBLIC_URL}/bgr`} component={bgr} />
        <Route exact path={`${process.env.PUBLIC_URL}/can`} component={can} />
        <Route exact path={`${process.env.PUBLIC_URL}/chn`} component={chn} />
        <Route exact path={`${process.env.PUBLIC_URL}/hrv`} component={hrv} />
        <Route exact path={`${process.env.PUBLIC_URL}/egy`} component={egy} />
        <Route exact path={`${process.env.PUBLIC_URL}/geo`} component={geo} />
        <Route exact path={`${process.env.PUBLIC_URL}/idn`} component={idn} />
        <Route exact path={`${process.env.PUBLIC_URL}/isr`} component={isr} />
        <Route exact path={`${process.env.PUBLIC_URL}/jpn`} component={jpn} />
        <Route exact path={`${process.env.PUBLIC_URL}/mys`} component={mys} />
        <Route exact path={`${process.env.PUBLIC_URL}/mar`} component={mar} />
        <Route exact path={`${process.env.PUBLIC_URL}/moz`} component={moz} />
        <Route exact path={`${process.env.PUBLIC_URL}/mmr`} component={mmr} />
        <Route exact path={`${process.env.PUBLIC_URL}/nzl`} component={nzl} />
        <Route exact path={`${process.env.PUBLIC_URL}/phl`} component={phl} />
        <Route exact path={`${process.env.PUBLIC_URL}/rus`} component={rus} />
        <Route exact path={`${process.env.PUBLIC_URL}/sgp`} component={sgp} />
        <Route exact path={`${process.env.PUBLIC_URL}/tha`} component={tha} />
        <Route exact path={`${process.env.PUBLIC_URL}/tur`} component={tur} />
        <Route exact path={`${process.env.PUBLIC_URL}/gbr`} component={gbr} />
        <Route exact path={`${process.env.PUBLIC_URL}/usa`} component={usa} />
        <Route exact path={`${process.env.PUBLIC_URL}/bel`} component={schengen} />
        <Route exact path={`${process.env.PUBLIC_URL}/fra`} component={schengen} />
        <Route exact path={`${process.env.PUBLIC_URL}/deu`} component={schengen} />
        <Route exact path={`${process.env.PUBLIC_URL}/grc`} component={schengen} />
        <Route exact path={`${process.env.PUBLIC_URL}/ita`} component={schengen} />
        <Route exact path={`${process.env.PUBLIC_URL}/lva`} component={schengen} />
        <Route exact path={`${process.env.PUBLIC_URL}/nor`} component={schengen} />
        <Route exact path={`${process.env.PUBLIC_URL}/pol`} component={schengen} />
        <Route exact path={`${process.env.PUBLIC_URL}/std`} component={std} />
        <Route exact path={`${process.env.PUBLIC_URL}/contact`} component={Contact} />
        <Route exact path={`${process.env.PUBLIC_URL}/about`} component={About} />
        <Route exact path={`${process.env.PUBLIC_URL}/goa`} component={Goa} />
        <Route exact path={`${process.env.PUBLIC_URL}/goapd1`} component={GoaPD1} />
        <Route exact path={`${process.env.PUBLIC_URL}/goapd2`} component={GoaPD2} />
        <Route exact path={`${process.env.PUBLIC_URL}/kerala`} component={Kerala} />
        <Route exact path={`${process.env.PUBLIC_URL}/keralapd1`} component={KeralaPD1} />
        <Route exact path={`${process.env.PUBLIC_URL}/keralapd2`} component={KeralaPD2} />
        <Route exact path={`${process.env.PUBLIC_URL}/sou`} component={Sou} />
        <Route exact path={`${process.env.PUBLIC_URL}/soupd1`} component={SouPD1} />
        <Route exact path={`${process.env.PUBLIC_URL}/soupd2`} component={SouPD2} />
        <Route exact path={`${process.env.PUBLIC_URL}/saputara`} component={Saputara} />
        <Route exact path={`${process.env.PUBLIC_URL}/sdk`} component={SDK} />
        <Route exact path={`${process.env.PUBLIC_URL}/orsang`} component={Orsang} />
        <Route exact path={`${process.env.PUBLIC_URL}/thailand`} component={Thailand} />
        <Route exact path={`${process.env.PUBLIC_URL}/and`} component={And} />
        <Route exact path={`${process.env.PUBLIC_URL}/andpd1`} component={andPD1} />
        <Route exact path={`${process.env.PUBLIC_URL}/andpd2`} component={andPD2} />
        <Route exact path={`${process.env.PUBLIC_URL}/andpd3`} component={andPD3} />
        <Route exact path={`${process.env.PUBLIC_URL}/cst`} component={CST} />
        <Route exact path={`${process.env.PUBLIC_URL}/cdy`} component={CDY} />
        <Route exact path={`${process.env.PUBLIC_URL}/hima`} component={Himachal} />
        <Route exact path={`${process.env.PUBLIC_URL}/eur`} component={Euro} />
        <Route exact path={`${process.env.PUBLIC_URL}/others`} component={Others} />
        <Route exact path={`${process.env.PUBLIC_URL}/passport`} component={Passport} />
        <Route exact path={`${process.env.PUBLIC_URL}/insurance`} component={Insurance} />
        <Route exact path={`${process.env.PUBLIC_URL}/car`} component={Car} />
        <Route exact path={`${process.env.PUBLIC_URL}/railway`} component={Railway} />
        <Route exact path={`${process.env.PUBLIC_URL}/form`} component={Form} />
        <Route exact path={`${process.env.PUBLIC_URL}/privacy`} component={Privacy} />
      <Footer />
      </Router>
    </>
  );
}

export default App;