import React from 'react'
import Card from '../Layout/HorizontalCard'
import '../../sass/Others.scss'

const Others = () => {
    return (
        <>
            <div className="others">
                <div className="title">
                    <h3>Our Other Services</h3>
                    <p>We also provides following services</p>
                </div>
                <div className="card-grp-others">
                    <Card
                        title="Passport"
                        content="An Indian passport is issued by the Indian Ministry of External Affairs to Indian citizens."
                        button="Apply Now"
                        href="/passport"
                    />
                    <Card
                        title="Travel Insurance"
                        content="Buying a travel insurance policy is also an investment therefore make the most of it. With Shree Hari Tours & Travels."
                        button="Apply Now"
                        href="/insurance"
                    />
                    <Card
                        title="Rent a Car"
                        content="Rent a Car with excellent Service. Book now with Shree Hari Tours & Travels for Low Prices! Find the Car for your trip."
                        button="Rent Now"
                        href="/car"
                    />
                    <Card
                        title="Railway Ticket"
                        content=" No matter where you are in the country, make a hassle-free railway reservation from us."
                        button="Book Now"
                        href="/railway"
                    />
                </div>
            </div>
        </>
    )
}

export default Others