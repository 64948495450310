import React from 'react'
import '../../sass/CheckList.scss'
import Button from '../Layout/Btn'

const Passport = () => {
    return (
        <>
            <div className="title">
                <h3>Passport</h3>
            </div>
            <div className="checklist">
                <div className="list">
                    <h4>DOCUMENTS REQUIRE FOR FRESH PASSPORT</h4>
                    <ol>
                        <li>SCHOOL LEAVING CERTIFICATE / BIRTH CERTIFICATE</li>
                        <li>ADHAR CARD / VOTER ID</li>
                        <li>PAN CARD</li>
                        <li>NATIONALISE BANK ACCOUNT PASSBOOK WITH LATEST ENTRY ( IF 18 YEARS ABOVE )</li>
                        <li>10TH OR 12TH MARKSHEET</li>
                        <li>LIGHT BILL / VERA BILL / GAS BILL / BSNL BILL LATEST AND 01 YEAR OLD – ( ANY TWO )</li>
                    </ol>
                </div>
                <div className="list">
                    <h4>DOCUMENTS REQUIRE FOR RENEWAL PASSPORT</h4>
                    <ol>
                        <li>PASSPORT</li>
                        <li>ADHAR CARD / VOTER ID</li>
                        <li>PAN CARD</li>
                        <li>NATIONALISE BANK ACCOUNT PASSBOOK WITH LATEST ENTRY ( IF 18 YEARS ABOVE )</li>
                        <li>10TH OR 12TH MARKSHEET</li>
                        <li>LIGHT BILL / VERA BILL / GAS BILL / BSNL BILL LATEST AND 01 YEAR OLD – ( ANY TWO )</li>
                    </ol>
                </div>
                <div style={{ padding: '10px' }}>
                    <p>
                        <b>NOTE: </b>
                        <ul>
                            <li>IF LESS THAN 18 YEARS , FATHER & MOTHER PASSPORT NEEDED</li>
                            <li>IF MARRIED LADY , MARRIAGE CERTIFICATE NEEDED</li>
                            <li>IF LESS THAN 05 YEARS , 35 X 35 MM PHOTOS WITH WHITE BACKGROUND – 6 PHOTOS</li>
                        </ul>
                    </p>
                </div>
            </div>
            <div className="cl-btn">
                <div className="pd-btn">
                    <Button download="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/downloads%2Fpassport.pdf?alt=media&token=904598d2-b55a-4b5d-b121-ee627d1b711a" />
                </div>
            </div>
        </>
    )
}

export default Passport;