import React from 'react'
import Card from '../../Layout/Card'
import '../../../sass/PackageCard.scss'

const And = () => {
    return (
        <>
            <div className="title">
                <h3>Andaman Tours</h3>
            </div>
            <div className="package-card">
                <div className="card-grp">
                    <Card
                        src="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/holidays%2Fandaman.jpg?alt=media&token=8fdc6f33-bc73-4169-8e47-09df4991e1b7"
                        title="Exotic Andaman Tour"
                        button="Explore Now"
                        href="/andPD1"
                    />
                    <Card
                        src="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/holidays%2Fandaman2.jpg?alt=media&token=12d51815-211a-4686-8f1a-0eaa4e3bee9d"
                        title="Mesmerizing Andaman Tour"
                        button="Explore Now"
                        href="/andPD2"
                    />
                    <Card
                        src="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/holidays%2Fandaman3.jpg?alt=media&token=04ca93f3-e4df-4d22-8b39-58118150ccce"
                        title="Best of Andamans Tour"
                        button="Explore Now"
                        href="/andPD3"
                    />
                </div>
            </div>
        </>
    )
}

export default And