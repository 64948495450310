import React from 'react'
import '../../sass/Flag.scss'

export const flags = [
    {
        country: 'Australia',
        code: 'aus'
    },
    {
        country: 'Bangladesh',
        code: 'bgd'
    },
    {
        country: 'Belgium',
        code: 'bel'
    },
    {
        country: 'Bulgaria',
        code: 'bgr'
    },
    {
        country: 'Canada',
        code: 'can'
    },
    {
        country: 'China',
        code: 'chn'
    },
    {
        country: 'Croatia',
        code: 'hrv'
    },
    {
        country: 'Egypt',
        code: 'egy'
    },
    {
        country: 'France',
        code: 'fra'
    },
    {
        country: 'Georgia',
        code: 'geo'
    },
    {
        country: 'Germany',
        code: 'deu'
    },
    {
        country: 'Greece',
        code: 'grc'
    },
    {
        country: 'Indonesia',
        code: 'idn'
    },
    {
        country: 'Israel',
        code: 'isr'
    },
    {
        country: 'Italy',
        code: 'ita'
    },
    {
        country: 'Japan',
        code: 'jpn'
    },
    {
        country: 'Latvia',
        code: 'lva'
    },
    {
        country: 'Malaysia',
        code: 'mys'
    },
    {
        country: 'Morocco',
        code: 'mar'
    },
    {
        country: 'Mozambique',
        code: 'moz'
    },
    {
        country: 'Myanmar',
        code: 'mmr'
    },
    {
        country: 'New Zealand',
        code: 'nzl'
    },
    {
        country: 'Norway',
        code: 'nor'
    },
    {
        country: 'Philippines',
        code: 'phl'
    },
    {
        country: 'Poland',
        code: 'pol'
    },
    {
        country: 'Russia',
        code: 'rus'
    },
    {
        country: 'Singapore',
        code: 'sgp'
    },
    {
        country: 'Thailand',
        code: 'tha'
    },
    {
        country: 'Turkey',
        code: 'tur'
    },
    {
        country: 'United Kingdom',
        code: 'gbr'
    },
    {
        country: 'United States',
        code: 'usa'
    }
]

const Flag = () => {
    const flagImg = flags.map(flag => {
        return (
            <div className="flag-grp">
                <a href={`/${flag.code}`}>
                    <img src={`https://restcountries.eu/data/${flag.code}.svg`} alt={flag.country} />
                    <h5>{flag.country}</h5>
                </a>
            </div>
        )
    }) 
    return (
        <div className="flags">
            {flagImg}
            <div className="flag-grp">
                <a href={`/std`}>
                    <img src="https://upload.wikimedia.org/wikipedia/commons/b/b7/Flag_of_Europe.svg" alt="{flag.country}" />
                    <h5>Student Exchange Visa Requirement</h5>
                </a>
            </div>
        </div>
    )
}

export default Flag;