import React from 'react'
import fire from '../../config/fire'
import firebase from 'firebase/app'
import 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import NumberFormat from 'react-number-format'
import CreatableSelect from 'react-select/creatable'
import Axios from 'axios';
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import '../../sass/Flights.scss'

class Flights extends React.Component {
    render() {
        return (
            <div className="flights">
                <div className='title'>
                    <h3>Air Tickets</h3>
                </div>
                <Navigation />
            </div>
        )
    }
}

const Navigation = () => {
    return (
        <div className="navigation">
            <Tabs defaultActiveKey="OneWay" id="uncontrolled-tab-example">
                <Tab eventKey="OneWay" title="One Way"> 
                    <OneWay />
                </Tab>
                <Tab eventKey="RoundTrip" title="Round Trip">
                    <RoundTrip />
                </Tab>
                <Tab eventKey="MultiCity" title="Multi-City">
                    <MultiCity />
                </Tab>
            </Tabs>
        </div>
    )
}

class OneWay extends React.Component {
    state = {
        name: '',
        number: '',
        from: null,
        dest: null,
        depart: '',
        arrival: '',
        pass: '',
        class: '',
        airports: [],
    }
    componentDidMount = () => {
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
            'size': 'invisible',
            'callback': function (response) {
                // reCAPTCHA solved, allow signInWithPhoneNumber.
                this.onClick();
            }
        });

        Axios.get('https://gist.githubusercontent.com/tdreyno/4278655/raw/7b0762c09b519f40397e4c3e100b097d861f5588/airports.json')
            .then(response => {
                //console.log(response.data)
                this.setState({
                    airports: response.data
                })
            })
    }

    onClick = (e) => {
        e.preventDefault();

        const phoneNumber = this.state.number;
        //const name = this.state.name;
        const appVerifier = window.recaptchaVerifier;
        fire
            .auth()
            .signInWithPhoneNumber(phoneNumber, appVerifier)
            .then(confirmationResult => {
                // success
                //console.log(confirmationResult)
                window.confirmationResult = confirmationResult;

                const verificationCode = window.prompt('Please enter the OTP code that was sent to your mobile device.');

                confirmationResult.confirm(verificationCode).then(function (result) {
                    // User signed in successfully.
                    // ...
                    //console.log("loged in...")
                    window.confirm('Thanks! You will be contacted shortly');
                }).then(
                    () => {
                        const db = firebase.firestore();

                        db.collection("oneway").add({
                            name: this.state.name,
                            number: this.state.number,
                            from: this.state.from,
                            dest: this.state.dest,
                            depart: this.state.depart,
                            arrival: this.state.arrival,
                            pass: this.state.pass,
                            class: this.state.class,
                        });
                    }
                ).catch(function (error) {
                    // User couldn't sign in (bad verification code?)
                    // ...
                    //console.log(error)
                    window.confirm('Invalid verification code please try again');
                });

            })
            .catch(error => {
                // error
            });
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
        //console.log(e.target.value)
    }

    handleFrom = (from) => {
        this.setState({ from: from.label });
        //console.log(from.label);
    }

    handleDest = (dest) => {
        this.setState({ dest: dest.label });
        //console.log(dest.label);
    }

    render() {
        const from = this.state.airports.map((from) => {
            return (
                { value: from.city, label: from.city }
            );
        })

        const dest = this.state.airports.map((airport) => {
            return { value: airport.city, label: airport.city };
        })

        return (
            <form className="form">
                <div className="group">
                    <div className="form-grp">
                        <label><i class="fa fa-user icon"></i>Name</label>
                        <input onChange={this.handleChange} value={this.state.name} name="name" type="text" placeholder="Full Name" />
                    </div>
                    <div className="form-grp">
                        <label><i class="fa fa-phone icon"></i>Number</label>
                        <NumberFormat prefix={"+91"} format="+91 #####-#####" onChange={this.handleChange} value={this.state.number} name="number" placeholder="Enter your Phone Number" required />
                        <span>OTP will sent on this Number</span>
                    </div>
                </div>
                <div className="group">
                    <div className="form-grp1">
                        <label>From</label>
                        <CreatableSelect
                            onChange={this.handleFrom}
                            name="from"
                            options={from}
                            //value={this.state.from}
                        />
                    </div>
                    <div className="form-icon">
                        <i className="material-icons md-48" style={{ color: '#fff' }}>compare_arrows</i>
                    </div>
                    <div className="form-grp1">
                        <label><i class="fa fa-map-marker-alt icon"></i>Destination</label>
                        <CreatableSelect 
                            onChange={this.handleDest} 
                            name="dest"
                            options={dest}
                            //value={this.state.dest}
                        />
                    </div>
                </div>
                <div className="group">
                    <div className="form-grp2">
                        <div className="form-grp3">
                            <label>DEPARTURE</label>
                            <input onChange={this.handleChange} value={this.state.depart} name="depart" type="date" placeholder="Departure date" />
                        </div>
                        <div className="form-grp3">
                            <label>ARRIVAL</label>
                            <input onChange={this.handleChange} value={this.state.arrival} name="arrival" type="date" placeholder="Arrival date"  />
                        </div>
                    </div>
                    <div className="form-grp2">
                        <div className="form-grp3">
                            <label>Passenger</label>
                            <input onChange={this.handleChange} value={this.state.pass} name="pass" type="number" min="1" placeholder="No. of passenger" />
                        </div>
                        <div className="form-grp3">
                            <label>Class</label>
                            <select onChange={this.handleChange} value={this.state.class} name="class">
                                <option>Select Class</option>
                                <option>Economy</option>
                                <option>Business</option>
                                <option>First class</option>
                                <option>Premium Economy</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div id="recaptcha-container"></div>
                <div className='form-grp'>
                    <button id="one" onClick={this.onClick}>Book Now</button>
                </div>
            </form>
        )
    }
}

class RoundTrip extends React.Component {
    state = {
        name: '',
        number: '',
        from: null,
        dest: null,
        depart: '',
        arrival: '',
        pass: '',
        class: '',
        airports: [],
    }
    componentDidMount() {
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
            'size': 'invisible',
            'callback': function (response) {
                // reCAPTCHA solved, allow signInWithPhoneNumber.
                this.onClick();
            }
        });

        Axios.get('https://gist.githubusercontent.com/tdreyno/4278655/raw/7b0762c09b519f40397e4c3e100b097d861f5588/airports.json')
            .then(response => {
                //console.log(response.data)
                this.setState({
                    airports: response.data
                })
            })
    }

    onClick = (e) => {
        e.preventDefault();

        const phoneNumber = this.state.number;
        //const name = this.state.name;
        const appVerifier = window.recaptchaVerifier;
        fire
            .auth()
            .signInWithPhoneNumber(phoneNumber, appVerifier)
            .then(confirmationResult => {
                // success
                //console.log(confirmationResult)
                window.confirmationResult = confirmationResult;

                const verificationCode = window.prompt('Please enter the OTP code that was sent to your mobile device.');

                confirmationResult.confirm(verificationCode).then(function (result) {
                    // User signed in successfully.
                    // ...
                    //console.log("loged in...")
                    window.confirm('Thanks! You will be contacted shortly');
                }).then(
                    () => {
                        const db = firebase.firestore();

                        db.collection("roundtrip").add({
                            name: this.state.name,
                            number: this.state.number,
                            from: this.state.from,
                            dest: this.state.dest,
                            depart: this.state.depart,
                            arrival: this.state.arrival,
                            pass: this.state.pass,
                            class: this.state.class,
                        });
                    }
                ).catch(function (error) {
                    // User couldn't sign in (bad verification code?)
                    // ...
                    //console.log(error)
                    window.confirm('Invalid verification code please try again');
                });

            })
            .catch(error => {
                // error
            });
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
        //console.log(e.target.value)
    }

    handleFrom = (from) => {
        this.setState({ from: from.label });
        //console.log(from.label);
    }

    handleDest = (dest) => {
        this.setState({ dest: dest.label });
        //console.log(dest.label);
    }

    render() {
        const from = this.state.airports.map((from) => {
            return (
                { value: from.city, label: from.city }
            );
        })

        const dest = this.state.airports.map((airport) => {
            return { value: airport.city, label: airport.city };
        })

        return (
            <form className="form">
                <div className="group">
                    <div className="form-grp">
                        <label><i class="fa fa-user icon"></i>Name</label>
                        <input onChange={this.handleChange} value={this.state.name} name="name" type="text" placeholder="Full Name" />
                    </div>
                    <div className="form-grp">
                        <label><i class="fa fa-phone icon"></i>Number</label>
                        <NumberFormat prefix={"+91"} format="+91 #####-#####" onChange={this.handleChange} value={this.state.number} name="number" placeholder="Enter your Phone Number" required />
                        <span>OTP will sent on this Number</span>
                    </div>
                </div>
                <div className="group">
                    <div className="form-grp1">
                        <label>From</label>
                        <CreatableSelect
                            onChange={this.handleFrom}
                            name="from"
                            options={from}
                        //value={this.state.from}
                        />
                    </div>
                    <div className="form-icon">
                        <i className="material-icons md-48" style={{ color: '#fff' }}>compare_arrows</i>
                    </div>
                    <div className="form-grp1">
                        <label><i class="fa fa-map-marker-alt icon"></i>Destination</label>
                        <CreatableSelect
                            onChange={this.handleDest}
                            name="dest"
                            options={dest}
                        //value={this.state.dest}
                        />
                    </div>
                </div>
                <div className="group">
                    <div className="form-grp2">
                        <div className="form-grp3">
                            <label>DEPARTURE</label>
                            <input onChange={this.handleChange} value={this.state.depart} name="depart" type="date" placeholder="Departure date" />
                        </div>
                        <div className="form-grp3">
                            <label>ARRIVAL</label>
                            <input onChange={this.handleChange} value={this.state.arrival} name="arrival" type="date" placeholder="Arrival date"  />
                        </div>
                    </div>
                    <div className="form-grp2">
                        <div className="form-grp3">
                            <label>Passenger</label>
                            <input onChange={this.handleChange} value={this.state.pass} name="pass" type="number" min="1" placeholder="No. of passenger" />
                        </div>
                        <div className="form-grp3">
                            <label>Class</label>
                            <select onChange={this.handleChange} value={this.state.class} name="class">
                                <option>Select Class</option>
                                <option>Economy</option>
                                <option>Business</option>
                                <option>First class</option>
                                <option>Premium Economy</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div id="recaptcha-container"></div>
                <div className='form-grp'>
                    <button id="round" onClick={this.onClick}>Book Now</button>
                </div>
            </form>
        )
    }
}

class MultiCity extends React.Component {
    state = {
        name: '',
        number: '',
        from: null,
        dest: null,
        depart: '',
        arrival: '',
        pass: '',
        class: '',
        airports: [],
        multi: [],
        from0: '',
        from1: '',
        from2: '',
        from3: '',
        dest0: '',
        dest1: '',
        dest2: '',
        dest3: '',
        date0: '',
        date1: '',
        date2: '',
        date3: '',
    }
    componentDidMount() {
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
            'size': 'invisible',
            'callback': function (response) {
                // reCAPTCHA solved, allow signInWithPhoneNumber.
                this.onClick();
            }
        });

        Axios.get('https://gist.githubusercontent.com/tdreyno/4278655/raw/7b0762c09b519f40397e4c3e100b097d861f5588/airports.json')
            .then(response => {
                //console.log(response.data)
                this.setState({
                    airports: response.data
                })
            })
    }

    onClick = (e) => {
        e.preventDefault();

        const phoneNumber = this.state.number;
        //const name = this.state.name;
        const appVerifier = window.recaptchaVerifier;
        fire
            .auth()
            .signInWithPhoneNumber(phoneNumber, appVerifier)
            .then(confirmationResult => {
                // success
                //console.log(confirmationResult)
                window.confirmationResult = confirmationResult;

                const verificationCode = window.prompt('Please enter the OTP code that was sent to your mobile device.');

                confirmationResult.confirm(verificationCode).then(function (result) {
                    // User signed in successfully.
                    // ...
                    //console.log("loged in...")
                    window.confirm('Thanks! You will be contacted shortly');
                }).then(
                    () => {
                        const db = firebase.firestore();

                        db.collection("multicity").add({
                            name: this.state.name,
                            number: this.state.number,
                            from: this.state.from,
                            dest: this.state.dest,
                            depart: this.state.depart,
                            arrival: this.state.arrival,
                            pass: this.state.pass,
                            class: this.state.class,
                            from0: this.state.from0,
                            from1: this.state.from1,
                            from2: this.state.from2,
                            from3: this.state.from3,
                            dest0: this.state.dest0,
                            dest1: this.state.dest1,
                            dest2: this.state.dest2,
                            dest3: this.state.dest3,
                            date0: this.state.date0,
                            date1: this.state.date1,
                            date2: this.state.date2,
                            date3: this.state.date3,
                        });
                    }
                ).catch(function (error) {
                    // User couldn't sign in (bad verification code?)
                    // ...
                    //console.log(error)
                    window.confirm('Invalid verification code please try again');
                });

            })
            .catch(error => {
                // error
            });
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
        //console.log(e.target.value)
    }

    handleFrom = (from) => {
        this.setState({ from: from.label });
        //console.log(from.label);
    }

    handleDest = (dest) => {
        this.setState({ dest: dest.label });
        //console.log(dest.label);
    }

    addForm = () => {
        if (this.state.multi.length < 4) {
            this.setState({
                multi: [
                    ...this.state.multi,
                    <AddForm 
                        onChange={this.handleChange}
                        from={`from${this.state.multi.length}`}
                        dest={`dest${this.state.multi.length}`}
                        date={`date${this.state.multi.length}`} 
                    />
                ]
            });
            //console.log(this.state.multi.length)
        }
    }
        
    render() {
        const from = this.state.airports.map((from) => {
            return (
                { value: from.city, label: from.city }
            );
        })

        const dest = this.state.airports.map((airport) => {
            return { value: airport.city, label: airport.city };
        })

        return (
            <form className="form">
                <div className="group">
                    <div className="form-grp">
                        <label><i class="fa fa-user icon"></i>Name</label>
                        <input onChange={this.handleChange} value={this.state.name} name="name" type="text" placeholder="Full Name" />
                    </div>
                    <div className="form-grp">
                        <label><i class="fa fa-phone icon"></i>Number</label>
                        <NumberFormat prefix={"+91"} format="+91 #####-#####" onChange={this.handleChange} value={this.state.number} name="number" placeholder="Enter your Phone Number" required />
                        <span>OTP will sent on this Number</span>
                    </div>
                </div>
                <div className="group">
                    <div className="form-grp1">
                        <label>From</label>
                        <CreatableSelect
                            onChange={this.handleFrom}
                            name="from"
                            options={from}
                        //value={this.state.from}
                        />
                    </div>
                    <div className="form-icon">
                        <i className="material-icons md-48" style={{ color: '#fff' }}>compare_arrows</i>
                    </div>
                    <div className="form-grp1">
                        <label><i class="fa fa-map-marker-alt icon"></i>Destination</label>
                        <CreatableSelect
                            onChange={this.handleDest}
                            name="dest"
                            options={dest}
                        //value={this.state.dest}
                        />
                    </div>
                </div>
                <div className="group">
                    <div className="form-grp2">
                        <div className="form-grp3">
                            <label>DEPARTURE</label>
                            <input onChange={this.handleChange} value={this.state.depart} name="depart" type="date" placeholder="Departure date" />
                        </div>
                        <div className="form-grp3">
                            <label>ARRIVAL</label>
                            <input onChange={this.handleChange} value={this.state.arrival} name="arrival" type="date" placeholder="Arrival date" />
                        </div>
                    </div>
                    <div className="form-grp2">
                        <div className="form-grp3">
                            <label>Passenger</label>
                            <input onChange={this.handleChange} value={this.state.pass} name="pass" type="number" min="1" placeholder="No. of passenger" />
                        </div>
                        <div className="form-grp3">
                            <label>Class</label>
                            <select onChange={this.handleChange} value={this.state.class} name="class">
                                <option>Select Class</option>
                                <option>Economy</option>
                                <option>Business</option>
                                <option>First class</option>
                                <option>Premium Economy</option>
                            </select>
                        </div>
                    </div>
                </div>
                {this.state.multi.map(city => city)}
                <div id="recaptcha-container"></div>
                <div className='form-grp'>
                    <button className="a" onClick={() => this.addForm()} style={{ background: '#14427a'}}>Add City</button>
                    <button id="multi" onClick={this.onClick}>Book Now</button>
                </div>
            </form>
        )
    }
}

const AddForm = props => {
    return (
        <>
            <div className="addForm">
                <div className="addform-grp">
                    <label>From</label>
                    <input type="text" name={props.from} onChange={props.onChange} placeholder="From" required />
                </div>
                <div className="addform-grp">
                    <label>Destination</label>
                    <input type="text" name={props.dest} onChange={props.onChange} placeholder="Destination" required />
                </div>
                <div className="addform-grp">
                    <label>Arrival</label>
                    <input type="date" name={props.date} onChange={props.onChange} placeholder="Arrival Date" required />
                </div>
            </div>
        </>
    )
}

export default Flights;
