import React from 'react'
import '../../../sass/CheckList.scss'
import Button from '../../Layout/Btn'

const sgp = () => {
    return (
        <>
            <div className="title">
                <h3>Singapore Visa check List (Tourist)</h3>
            </div>
            <div className="checklist">
                <div className="list">
                    <h4>For Individual</h4>
                    <ol>
                        <li>Current Passport</li>
                        <li>02 Photographs (35x45, 80% Face, matt finish with white background)</li>
                        <li>Last 03 Months Updated savings Bank Statement with Bank Stamp & Sign (Min. Bal. 50,000 Per Person)</li>
                        <li>Copy of Pan Card</li>
                        <li>Hotel confirmation</li>
                        <li>Confirmed Return Tickets</li>
                        <li>Company Letter Head with Stamp & Sign/ Visiting Card</li>
                    </ol>
                </div>
                <div className="list">
                    <h4>For Honeymooners</h4>
                    <ol>
                        <li>Marriage Certificate / Marriage invitation Card & Marriage Photographs</li>
                        <li>NOC From Both Parents with ID Copy Attached</li>
                    </ol>
                </div>
                <div className="list">
                    <h4>For Minor/Student</h4>
                    <ol>
                        <li>School /College Id Proof with Address</li>
                    </ol>
                </div>
                <div className="list">
                    <h4>For Sponsor</h4>
                    <ol>
                        <li>Passport Copy / Valid Visa Copy</li>
                        <li>Invitation Letter with mentioned all the details</li>
                        <li>Employment Id Proof</li>
                    </ol>
                </div>
            </div>
            <div className="cl-btn">
                <div className="pd-btn">
                    <Button download="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/downloads%2F18.pdf?alt=media&token=e40f8af3-5411-4051-a2ab-4ecca2d26e1f" />
                </div>
            </div>
        </>
    )
}

export default sgp;