import React from 'react'
import '../../../sass/CheckList.scss'
import Button from '../../Layout/Btn'

const std = () => {
    return (
        <>
            <div className="title">
                <h3>Student Exchange Visa Requirement</h3>
            </div>
            <div className="checklist">
                <div className="list">
                    <h4>For Applicant</h4>
                    <ol>
                        <li>All Passports</li>
                        <li>02 New Photographs ( 35mm x 45mm, 80% face, matte finish, white background)</li>
                        <li>Copy of Pan Card</li>
                        <li>Updated Personal 06 month Bank Statement with bank stamp & sign</li>
                        <li>School / College Id Proof</li>
                        <li>Leaving Certificate or Mark Sheet (If Education is Complete)</li>
                        <li>Original Leave Sanctioned Letter from School / College with Stamp & Sign of Principal</li>
                        <li>Letter from Indian Institute</li>
                    </ol>
                </div>
                <div className="list">
                    <h4>For Parents</h4>
                    <ol>
                        <li>Parents Passport copy</li>
                        <li>NOC Letter with stamp & sign  on 100 Rs. Stamp Paper (Must Mentioned Travel Details & accommodation)</li>
                        <li>Copy of Pan Card</li>
                        <li>Updated Last 06 months Bank Statement with Bank stamp & Sign</li>
                        <li>Last 03 Years Income Tax Returns</li>
                        <li>03 Letter Head of Company (with stamp & sign)</li>
                        <li>Company Proof in English (Registration/PartnershipDeed/Memorandum</li>
                        <li>Residence Proof</li>
                        <li>If Parents are employed, last 03 months salary slip, employment letter from employee and employee id card</li>
                    </ol>
                </div>
                <div className="list">
                    <h4>For Sponsor / Invitee Person:</h4>
                    <ol>
                        <li>Passport Copy /  Id Proof</li>
                        <li>Sponsor Letter / Invitation Letter (Must Mentioned Actual Travel Date)</li>
                        <li>Sponsorship Form</li>
                        <li>Accommodation Letter</li>
                        <li>Utility Bill (Residence Proof )</li>
                    </ol>
                </div>
            </div>
            <div className="cl-btn">
                <div className="pd-btn">
                    <Button download="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/downloads%2F22.pdf?alt=media&token=b3e4e5d7-a60b-4368-ad59-e02b4e83491c" />
                </div>
            </div>
        </>
    )
}

export default std;