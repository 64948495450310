import React from 'react'
import '../../../sass/CheckList.scss'
import Button from '../../Layout/Btn'

const idn = () => {
    return (
        <>
            <div className="title">
                <h3>Indonesia Visa Checklist</h3>
            </div>
            <div className="checklist">
                <div className="list">
                    <ol>
                        <li>Old & New Passport</li>
                        <li>02 New Photographs (35x45, 80% face on matte finish paper with white background, without spectacles)</li>
                        <li>Visa Application Form</li>
                        <li>Updated 06 Months Savings Bank Statement with Bank stamp & sign on every page (Min. Bal Per Person 1,00,000)</li>
                        <li>Personal Cover letter</li>
                        <li>Parents NOC letter with passport copy & Sign</li>
                        <li>Parents original saving bank statements for last 3 months with bank stamp & sign</li>
                        <li>Original bonafide certificate from college with mentioned travel date</li>
                        <li>College ID Proof</li>
                        <li>New invitation letter with Indonesian language</li>
                        <li>Immigration approval</li>
                        <li>Return Ticket</li>
                    </ol>
                </div>
            </div>
            <div className="cl-btn">
                <div className="pd-btn">
                    <Button download="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/downloads%2F8.pdf?alt=media&token=07f8e98a-ce36-48cc-8b6d-88bb073f31cb" />
                </div>
            </div>
        </>
    )
}

export default idn;