import React from 'react'
import PackageDetails from '../PackageDetails'

const Himachal = () => {
    return (
        <div>
            <PackageDetails
                src="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/holidays%2Fhimachal.webp?alt=media&token=c9db03a1-116d-40cd-a40d-77ab8900097c"
                package="Best of Himachal Tour 9N/10D Package"
                day1="Day 01"
                content1="Arrival to DelhiAirport/Railway Station,Transfer to Delhi Hotel Dinner and Overnight stay @ Hotel"
                day2="Day 02"
                content2="After Breakfast Checkout from Hotel, proceed to Shimla Hotel, Evening Check-in to mention hotel in Shimla. Dinner @Hotel, after dinner free time go around hotel Overnight @Hotel"
                day3="Day 03"
                content3="Take breakfast @hotel, after breakfast we willproceed for sightseeing of Kufri, Jakhoo Hills, Hanuman Temple, Mall Road, Etc. evening back to hotel take a dinnerand overnight @ Hotel"
                day4="Day 04"
                content4="After Breakfast Checkout from hotel we will proceed for our next destination of Manali approx. 254 km. During our way to Manali we will go through KulluSightseeing. Check-in to Manali Hotel and free time to go around hotel area Dinner and Overnight @hotel"
                day5="Day 05"
                content5="After breakfast our vehicle will be ready to go for Manali's Most Famous Hadimba devi Temple,Tibetan monastery, Mall Road, Vanvihaar, Jogini Fall Etc. late evening back to hotel Dinner and Overnight @hotel"
                day6="Day 06"
                content6="After breakfast will go to Solang valley the most romantic place in Manali. Also visit a Vashisth Kund(Hot Water Spring)return to Hotel Dinner and Overnight @ hotel"
                day7="Day 07"
                content7="After Breakfast Early morning checkout from Manali hotel and transfer to Dharamshala. Approx. 240km. After Check-in to hotel, Get ready to go for Dharmshala Cricket Stadium and Dalai Lama temple back to hotel Dinner and Overnight @ Hotel"
                day8="Day 08"
                content8="Early Breakfast in Hotel Check out from hotel and journey will continue to Dalhousie, 122km. after check-in to hotel visit of Khajjiarknownas a Mini Switzerland, dinner and overnight @ Hote"
                day9="Day 09"
                content9="After breakfast check out from hotel, will proceed to our next destination Amritsar During this way will pass through Dalhousie's local sightseeing. Reached to Amritsar at afternoon evening we will go to Wagah Border late evening back to hotel after dinner you can go to Golden temple by your own as it's on walking distance."
                day10="Day 10"
                content10="After breakfast checkout from hotel @ 10.00am free time to go to Jilyawala baugh and Golden temple, Drop to DelhiAirport/Railway Station."
                dl="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/itinarary%2Fhima.pdf?alt=media&token=4228ae94-d8e8-4949-894b-13a9db283cfe"
            />
        </div>
    )
}

export default Himachal;
