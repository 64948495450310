import firebase from 'firebase/app'
import 'firebase/app'
import 'firebase/storage'

// Your web app's Firebase configuration
var firebaseConfig = {
    apiKey: "AIzaSyCPQmN_WPZStJMQJhD8IS7gCdoBaGC9XEQ",
    authDomain: "website-db3cf.firebaseapp.com",
    databaseURL: "https://website-db3cf.firebaseio.com",
    projectId: "website-db3cf",
    storageBucket: "website-db3cf.appspot.com",
    messagingSenderId: "336505218851",
    appId: "1:336505218851:web:8f27be3e9e27d9a3254e96",
    measurementId: "G-1Q2PHVF5BD"
};
// Initialize Firebase
const fire = firebase.initializeApp(firebaseConfig);

export default fire;