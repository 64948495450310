import React from 'react';
import '../../sass/HorizontalCard.scss';

const HorizontalCard = props => {
    return (
        <>
            <div className="horizontalCard">
                <div className="h-cardBody">
                    <h3 className="h-cardTitle">{props.title}</h3>
                    <p className="cardContent">{props.content}</p>
                    <a className="h-button" href={props.href}>{props.button}</a>
                </div>
            </div>
        </>
    )
}

export default HorizontalCard;