import React from 'react'
import '../../../sass/CheckList.scss'
import Button from '../../Layout/Btn'

const tha = () => {
    return (
        <>
            <div className="title">
                <h3>Thailand Visa check List (Tourist)</h3>
            </div>
            <div className="checklist">
                <div className="list">
                    <h4>For Individual</h4>
                    <ol>
                        <li>Current Passport</li>
                        <li>Copy of Pan Card</li>
                        <li>02 Photographs (35x45, 80% Face, matt finish with white background without Spectacles)</li>
                        <li>Updated 06 months bank Statement with bank stamp & sign (Mini. balance 50,000 Per Person) / Credit Card Statement / Foreign Exchange Receipt of 700 USD <span><b>Please Note: </b>Consulate will not accept FOREX receipt for the first time travelers.</span></li>
                        <li>02 Blank Letter Head with Stamp & Sign</li>
                        <li>Hotel confirmation</li>
                        <li>Confirmed Return Tickets</li>
                        <li>02 Blank Letter Head / Visiting Card (Occupation Proof)</li>
                    </ol>
                </div>
                <div className="list">
                    <h4>For Honeymooners</h4>
                    <ol>
                        <li>Marriage Certificate & Marriage invitation Card</li>
                    </ol>
                </div>
                <div className="list">
                    <h4>For Minor/Student</h4>
                    <ol>
                        <li>School /College Id Proof with Address</li>
                    </ol>
                </div>
            </div>
            <div className="cl-btn">
                <div className="pd-btn">
                    <Button download="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/downloads%2F19.pdf?alt=media&token=3db9486c-6e3e-4fd8-bbf2-8c29fdfd2694" />
                </div>
            </div>
        </>
    )
}

export default tha;