import React from 'react'
import PackageDetails from '../PackageDetails'

const Saputara = () => {
    return (
        <div>
            <PackageDetails
                src="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/holidays%2Fsaputara.jpg?alt=media&token=ed3c3e02-f923-4b31-adc2-002c23960bd0"
                package="Saputara Trip"
                content1="Early Morning Departure at 05:00 a.m. from Surat."
                content2="Arrival at Botanical Garden, Near Vaghai at around 8:00 am to 8:30 am."
                content3="Visit Botanical garden, Giradhodh and onward trip to Saputara Hill Station. Arrival at around 1:00pm at Saputara."
                content4="Day at leisure. One can visit Boating, Ropeway, Sunset Point, Rose Garden, Table Point, Snake Musuem etc at own expense."
                content5="After full day of enjoyment at Saputara, Arrival at Surat around 12:00 am to 12:30 am."
                hide6="hide6"
                hide7="hide7"
                hide8="hide8"
                hide9="hide9"
                hide10="hide10"
                dl="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/itinarary%2Fsapu.pdf?alt=media&token=5cb28132-31d6-4b9a-af2f-eb167e59e900"
            />
        </div>
    )
}

export default Saputara;
