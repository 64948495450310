import React from 'react'
import '../../../sass/CheckList.scss'
import Button from '../../Layout/Btn'

const mar = () => {
    return (
        <>
            <div className="title">
                <h3>Morocco Visa check List (Internship Purpose)</h3>
            </div>
            <div className="checklist">
                <div className="list">
                    <ol>
                        <li>All Passports</li>
                        <li>02 New Photographs ( 35x45, 80% face, matte finish with white background, Without spectacles)</li>
                        <li>Copy of Adhar Card</li>
                        <li>Last 03 Months Savings Bank Statement with bank stamp & sign with Minimum Balance 2,00,000 Per Person</li>
                        <li>Company Proof ( Registration / Partnership Deed / MOA)</li>
                        <li>Hotel Confirmation / Original Invitation Letter</li>
                        <li>03 Company Letter Head with Stamp & Sign</li>
                        <li>Return Tickets</li>
                    </ol>
                </div>
            </div>
            <div className="cl-btn">
                <div className="pd-btn">
                    <Button download="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/downloads%2F12.pdf?alt=media&token=b32bc24f-c790-4032-84fa-5a75904ca095" />
                </div>
            </div>
        </>
    )
}

export default mar;