import React from 'react'
import PackageDetails from '../PackageDetails'

const SDK = () => {
    return (
        <div>
            <PackageDetails
                src="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/holidays%2FDudhani.jpeg?alt=media&token=3e8609f0-d0ea-400b-bd07-7c7bce49d331"
                package="Silvassa, Dudhani & Khanvel Trip"
                content1="Departure From Surat."
                content2="Arrival at Chikhli Mallika Arjun Temple."
                content3="Enroute Brindaban Temple, Khanvel, optional visit to Temple."
                content4="Depart for Deer park and National park, Khanvel.(Entry Ticket not included)"
                content5="Return to Brindaban Temple."
                content6="Depart Dudhani where you can enjoy Boating and Garden Visit( On own expense)."
                content7="Visit to Silvassa Garden."
                content8="Depart from Silvassa, return to Surat."
                hide9="hide9"
                hide10="hide10"
                dl="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/itinarary%2Fsdk.pdf?alt=media&token=fc3b1199-0858-4a8d-9206-38a8fb748029"
            />
        </div>
    )
}

export default SDK;
