import React from 'react'
import Itinarary from './Itinarary'
import '../../sass/PackageDetails.scss'
import Button from '../Layout/Button'

const PackageDetails = props => {
    return (
        <div className="package-details">
            <img src={props.src} alt="" />
            <div className="title">
                <h3>{props.package}</h3>
            </div>
            <div className="">
                <div className="title" style={{textAlign: 'left', color: '#777'}}>
                    <h3>Itinarary</h3>
                </div>
                <Itinarary
                    title={props.day1}
                    content={props.content1}
                    hide={props.hide1}
                />
                <Itinarary
                    title={props.day2}
                    content={props.content2}
                    hide={props.hide2}
                />
                <Itinarary
                    title={props.day3}
                    content={props.content3}
                    hide={props.hide3}
                />
                <Itinarary
                    title={props.day4}
                    content={props.content4}
                    hide={props.hide4}
                />
                <Itinarary
                    title={props.day5}
                    content={props.content5}
                    hide={props.hide5}
                />
                <Itinarary
                    title={props.day6}
                    content={props.content6}
                    hide={props.hide6}
                />
                <Itinarary
                    title={props.day7}
                    content={props.content7}
                    hide={props.hide7}
                />
                <Itinarary
                    title={props.day8}
                    content={props.content8}
                    hide={props.hide8}
                />
                <Itinarary
                    title={props.day9}
                    content={props.content9}
                    hide={props.hide9}
                />
                <Itinarary
                    title={props.day10}
                    content={props.content10}
                    hide={props.hide10}
                />
            </div>
            <div className="pd-btn">
                <Button download={props.dl} />
            </div>
        </div>
    )
}

export default PackageDetails;