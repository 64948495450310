import React from 'react'
import firebase from 'firebase/app'
import 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import NumberFormat from 'react-number-format'
import fire from '../../config/fire'
import '../../sass/Bus.scss'

class Bus extends React.Component {
    state = {
        name: '',
        number: '',
        dest: '',
        start: '',
        date: ''
    }
    componentDidMount() {
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
            'size': 'invisible',
            'callback': function (response) {
                // reCAPTCHA solved, allow signInWithPhoneNumber.
                this.onClick();
            }
        });
    }

    onClick = (e) => {
        e.preventDefault();

        const phoneNumber = this.state.number;
        //const name = this.state.name;
        const appVerifier = window.recaptchaVerifier;
        fire
            .auth()
            .signInWithPhoneNumber(phoneNumber, appVerifier)
            .then(confirmationResult => {
                // success
                //console.log(confirmationResult)
                window.confirmationResult = confirmationResult;

                const verificationCode = window.prompt('Please enter the OTP code that was sent to your mobile device.');

                confirmationResult.confirm(verificationCode).then(function (result) {
                    // User signed in successfully.
                    // ...
                    //console.log("loged in...")
                    window.confirm('Thanks! You will be contacted shortly');
                }).then(
                    () => {
                        const db = firebase.firestore();

                        db.collection("bus").add({
                            name: this.state.name,
                            number: this.state.number,
                            dest: this.state.dest,
                            start: this.state.start,
                            date: this.state.date
                        });
                    }
                ).catch(function (error) {
                    // User couldn't sign in (bad verification code?)
                    // ...
                    //console.log(error)
                    window.confirm('Invalid verification code please try again');
                });

            })
            .catch(error => {
                // error
            });
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
        //console.log(e.target.value)
    }
    render() {
        return (
            <>
                <div className="bus">
                    <div className="title">
                        <h3>Bus Hire</h3>
                    </div>
                    <div className="bus-form">
                        <form className="b-form">
                            <div className="form-grp">
                                <input type="text" onChange={this.handleChange} name="start" placeholder="Starting Point" />
                                <input type="text" onChange={this.handleChange} name="dest" placeholder="Destination" />
                                <input type="date" onChange={this.handleChange} name="date" data-placeholder="MM/DD/YYYY" onfocus="(this.type='date')" />
                                <input type="text" onChange={this.handleChange} name="name" placeholder="Your Full Name" required />
                                <NumberFormat prefix={"+91"} format="+91 #####-#####" onChange={this.handleChange} name="number" placeholder="Enter your Phone Number" required />
                                <button className="a" onClick={this.onClick}>Hire Now</button>
                                <div id="recaptcha-container"></div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="title">
                    <h3 style={{ textAlign: 'left', fontSize: '1.2rem' }}>Bus Images</h3>
                </div>
                <div className="img-grp">
                    <img src="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/images%2Fbus1.jpeg?alt=media&token=e2e5ac71-1aea-4451-9d38-a6243d10f637" alt="" />
                    <img src="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/images%2Fbus2.jpeg?alt=media&token=cee6c6ad-a8a7-4e0b-ba24-48a5c3611144" alt="" />
                    <img src="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/images%2Fbus3.jpeg?alt=media&token=d1306156-04a1-4a2c-9773-763a31f3e0b2" alt="" />
                    <img src="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/images%2Fbus4.jpeg?alt=media&token=e8c739aa-1ca3-4c2d-bd26-cd410887000d" alt="" />
                </div>
            </>
        )
    }
}

export default Bus;