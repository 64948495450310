import React from 'react'
import '../../../sass/CheckList.scss'
import Button from '../../Layout/Btn'

const bgd = () => {
    return (
        <>
            <div className="title">
                <h3>Bangladesh Visa Check List (Tourist)</h3>
            </div>
            <div className="checklist">
                <div className="list">
                    <ol>
                        <li>Old & NewPassport</li>
                        <li>02 New Photographs ( 35x45, 80% face, matte finish with white background)</li>
                        <li>Copy of Pan Card</li>
                        <li>03 Letter Heads</li>
                        <li>Confirm Hotel Booking with Confirmation letter from Bangladesh</li>
                        <li>If  Proprietor or Director of company then Indian Company Registration.</li>
                        <li>Visiting card or Company id of the applicant</li>
                        <li>Confirmed Return Air Tickets</li>
                    </ol>
                </div>
            </div>
            <div className="cl-btn">
                <div className="pd-btn">
                    <Button download="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/downloads%2FShree%20Hari%20Tours%20%26%20Travels1.pdf?alt=media&token=528c64d1-0f97-47d8-9ebf-90c1cf04fb40" />
                </div>
            </div>
        </>
    )
}

export default bgd;