import React from 'react'
import { Link } from 'react-router-dom'
import '../../sass/Card.scss'

const Card = props => {
    return (
        <>
            <div className="verticalCard">
                <div className="cardImg">
                    <img src={props.src} alt={props.alt} />
                </div>
                <div className="cardBody">
                    <h3 className="cardTitle">{props.title}</h3>
                    <p className="cardContent">{props.content}</p>
                    <Link className="button" to={props.href}>{ props.button }</Link>
                </div>
            </div>
        </>
    )
}

export default Card;
