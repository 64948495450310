import React from 'react'
import { Timeline, TimelineEvent } from 'react-event-timeline'
import '../../../sass/Itinarary.scss'
import '../../../sass/PackageDetails.scss'
import Button from '../../Layout/Button'

const PackageDetails = props => {
    return (
        <div className="package-details">
            <img src="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/holidays%2Feurope.jpg?alt=media&token=75a0aeb1-e507-4f7d-bd83-8f4a9ed5da86" alt="" />
            <div className="title">
                <h3>ICCONIC EUROPE 12 NIGHTS/13 DAYS </h3>
            </div>
            <div className="">
                <div className="title" style={{ textAlign: 'left', color: '#777' }}>
                    <h3>Itinarary</h3>
                </div>
                <Timeline className={props.hide} lineColor="#14427a">
                    <div className="timeline">
                        <TimelineEvent className={`${props.hide}`} container="card" cardHeaderStyle={{ background: '#14427a' }} titleStyle={{ fontSize: '1.2rem', fontWeight: 'bold' }} bubbleStyle={{ border: "1px solid #14427a", background: "#14427a" }} title="Day 01: INDIA – ARRIVE AT PARIS – EVENING RIVER SEINE CRUISE RIDE ">Welcome to Paris, A romantic city with wonderful people, Capital of France,European city anda global center for art, fashion, gastronomy and culture. On arrival get transferred to hotel forcheck inn. (Standard check-in time is 1400 hrs). Later in evening enjoy “Evening Seine RiverCruise Ride”. Later enjoydinner at Indian Dinner at restaurant before we return back to hotel.Overnight stay in Paris</TimelineEvent>
                    </div>
                </Timeline>
                <Timeline className={props.hide} lineColor="#14427a">
                    <div className="timeline">
                        <TimelineEvent className={`${props.hide}`} container="card" cardHeaderStyle={{ background: '#14427a' }} titleStyle={{ fontSize: '1.2rem', fontWeight: 'bold' }} bubbleStyle={{ border: "1px solid #14427a", background: "#14427a" }} title="Day 02: VISIT 3RD LEVEL OF EIFFEL TOWER – GUIDED TOUR OF PARIS">After a continental breakfast at the hotel, drive towards city to visit the most famous landmarkof Paris, the “Eiffel Tower,” and enjoy a panoramic view of the city from its3rd level. Built in1889, and designed by the young engineer Gustav Eiffel, the tower stands 324 meters (1,063ft) tall. After visiting the Eiffel Tower get ready for Guided city tour of Paris to visit importantplaces such as Arc De Triomphe, Champs Elysees, Grand Palais, Petit Palais, NationalAssembly, Alexander Bridge, Concorde Square, Magdalene Church, Opera Garnier, LouvrePalace, Cite Island, French Academy, Sorbonne University, Orsay Museum, Church of theInvalides, Military Academy, Trocadero Square, Palais De Chaillot and the Notre DameCathedral. After dinner return to hotel. Overnight in Paris</TimelineEvent>
                    </div>
                </Timeline>
                <Timeline className={props.hide} lineColor="#14427a">
                    <div className="timeline">
                        <TimelineEvent className={`${props.hide}`} container="card" cardHeaderStyle={{ background: '#14427a' }} titleStyle={{ fontSize: '1.2rem', fontWeight: 'bold' }} bubbleStyle={{ border: "1px solid #14427a", background: "#14427a" }} title="Day 03: FULL DAY TOUR OF DISNEYLAND – 01 DAY 01 PASS">After   a   continental   breakfast,   proceed   for   an   adventurous   and   fun   filled   time   atDisneylandParis, where you can enjoy many thrilling rides and fascinating shows. Meet yourfavoritecartoon characters and enjoy the jovial atmosphere with your whole family. The resortoffers a plethora of attractions and theme parades from the wonderful world of Disney. Later,the brilliant’ Illumination Tour of Paris' includes a drive past the famous monuments andbuildings of Paris at night to witness their dazzling illumination. Return to the hotel after thetour for a comfortable overnight stay. Have a comfortable overnight Stay at the hotel.</TimelineEvent>
                    </div>
                </Timeline>
                <Timeline className={props.hide} lineColor="#14427a">
                    <div className="timeline">
                        <TimelineEvent className={`${props.hide}`} container="card" cardHeaderStyle={{ background: '#14427a' }} titleStyle={{ fontSize: '1.2rem', fontWeight: 'bold' }} bubbleStyle={{ border: "1px solid #14427a", background: "#14427a" }} title="Day 04: ARIS TO GENEVA – PHOTOSTOP AT JET’D’EAU FOUNTAIN & UNHEADQUARTERS - VILLARS">After a continental breakfast at the hotel, check out from hotel and depart to Geneva. Uponarrival we embark on an orientation tour of Geneva and make a stop at the English GardensJardin Des Anglais for a stroll past the lakefront. Witness the famous JetD’Eau, which is a 130-metre-high jet of water on the lake.Thereafter, enjoy a drive past theBrunswick Monument and see the Palais De Nations, the European Headquarters of the UNO. Dinner at hotel.Overnightstay in Switzerland.</TimelineEvent>
                    </div>
                </Timeline>
                <Timeline className={props.hide} lineColor="#14427a">
                    <div className="timeline">
                        <TimelineEvent className={`${props.hide}`} container="card" cardHeaderStyle={{ background: '#14427a' }} titleStyle={{ fontSize: '1.2rem', fontWeight: 'bold' }} bubbleStyle={{ border: "1px solid #14427a", background: "#14427a" }} title="Day 05: EVEY ORIENTATION TOUR –NESTLE CHOCOLATE FACTORY – BERNORIENTATION TOUR">After a continental breakfast at the hotel, check out, sit back and relax in your coach as we foran unforgettable experience during a day out at the Maison Cailler in Broc the visitors canwatch live as the ingredients of chocolate are processed. ... Sensation, knowledge, anddiscovery, pleasure: the original visitors' tour at the Broc chocolate factory has something foreveryone. Later after lunch proceed to Interlaken. Enjoy free time at local market ofInterlaken. Dinner and Overnight stay at Hotel in Andermatt, Switzerland.</TimelineEvent>
                    </div>
                </Timeline>
                <Timeline className={props.hide} lineColor="#14427a">
                    <div className="timeline">
                        <TimelineEvent className={`${props.hide}`} container="card" cardHeaderStyle={{ background: '#14427a' }} titleStyle={{ fontSize: '1.2rem', fontWeight: 'bold' }} bubbleStyle={{ border: "1px solid #14427a", background: "#14427a" }} title="Day 06: VISIT OF MOUNT TITLIS WITH ICE FLYER – ORIENTATION TOUR OFLUCERNE">After   breakfast,   begin   the   adventurous   journey   to  Mt.   Titlis.   The   hair-raising   andunforgettable 45 minutes long ride to the top, switching three different cable cars, is nothingshort of awe-inspiring. Along the way you will witness the scenery change from greenmeadows and scenic lakes to snow-clad mountains. The last leg of the ride is on the famousRot air, which is the world's first revolving cable car, offering beautiful panoramic views of thesurrounding glaciers, mountains and scenery. Once atop Mt. Titlis, what awaits visitors arebreath-taking views of the Alps from either the sun terrace or from the famous south facewindow of the mountain. After the great Mt. Titlis experience, we proceed to Lucerne. Take anorientation tour of Lucerne, which is a beautiful city located at the northern tip of LakeLucerne. Walk along the banks of Lake Lucerne to experience attractions like the Kapellbrucke(The Chapel Bridge) and the Spreuerbrucke, the two traditional wooden bridges of Lucerne.Thereafter spend some free time shopping at the famous "Bucherer& Casa Grande" for Swisswatches, Chocolates and souvenirs. Dinner and Overnight stay at Hotel in Andermatt,Switzerland</TimelineEvent>
                    </div>
                </Timeline>
                <Timeline className={props.hide} lineColor="#14427a">
                    <div className="timeline">
                        <TimelineEvent className={`${props.hide}`} container="card" cardHeaderStyle={{ background: '#14427a' }} titleStyle={{ fontSize: '1.2rem', fontWeight: 'bold' }} bubbleStyle={{ border: "1px solid #14427a", background: "#14427a" }} title="Day 07: FULL DAY TOUR TO MOUNT JUNGFRAU – TOP OF THE EUROPE">After a scrumptious breakfast, enjoy the day tour to “MountJungfrau”, often referred to asthe Top of Europe, and the charming town of Interlaken. Jungfrau makes for a memorableencounter with snow and ice, and has all it takes to become the high point of any tour ofEurope. Tourists are often amazed at the scenic splendor they witness from the Cog-Wheeltrain, which climbs up to a height of 11,333 feet to reach Jungfrau, the highest railway stationin the world! The Cog-Wheel train is one of the best and most incredible train journeys in theworld. The panoramic views of Switzerland from atop the mountain are breath-taking. Eveningat your own leisure. Dinner and Overnight stay at Hotel in Andermatt, Switzerland.</TimelineEvent>
                    </div>
                </Timeline>
                <Timeline className={props.hide} lineColor="#14427a">
                    <div className="timeline">
                        <TimelineEvent className={`${props.hide}`} container="card" cardHeaderStyle={{ background: '#14427a' }} titleStyle={{ fontSize: '1.2rem', fontWeight: 'bold' }} bubbleStyle={{ border: "1px solid #14427a", background: "#14427a" }} title="Day 08: VISIT RHINE FALLS – BLACK FOREST (CUCKOO CLOCK) & LAKE TITISEE">After a continental breakfast at the hotel, check out, sit back and relax in your coach as wedrive to Neuhausen to view the magnificent Rhine Falls. The Rhine Falls is the largest plainwaterfall in Europe. The falls are located on the Upper Rhine between the municipalities ofNeuhausen am Rheinfall and Laufen-Uhwiesen, near the town of Schaffhausen. Later weproceed into Hofgutsternen, Black Forest. Here is ‘Drubba’, the home of the world-famouscuckoo clock. Get invited for a free demonstration and buy one for your home – a prizedpossession. Dinner and Overnight stay at Hotel in Andermatt, Switzerland.</TimelineEvent>
                    </div>
                </Timeline>
                <Timeline className={props.hide} lineColor="#14427a">
                    <div className="timeline">
                        <TimelineEvent className={`${props.hide}`} container="card" cardHeaderStyle={{ background: '#14427a' }} titleStyle={{ fontSize: '1.2rem', fontWeight: 'bold' }} bubbleStyle={{ border: "1px solid #14427a", background: "#14427a" }} title="Day 09: ZURICH – INNSBRUCK – VISIT OF SWAROVSKI CRYSTAL MUSEUME ">After a continental breakfast at hotel, check out and proceed for fun filled day to explore themost scenic country of Europe – Austria. Upon arrival we proceed to Wattens, where you willvisit the world-famous Swarovski Crystal Museum & Shop. Enjoy a fascinating experiencein to the ‘world of crystals. An experience that leaves you amazed at the dazzling glitter asyou walk through this shop. A great opportunity to buy a souvenir for your family. Later in theevening have dinner at Hotel. Overnight stay in Innsbruck.</TimelineEvent>
                    </div>
                </Timeline>
                <Timeline className={props.hide} lineColor="#14427a">
                    <div className="timeline">
                        <TimelineEvent className={`${props.hide}`} container="card" cardHeaderStyle={{ background: '#14427a' }} titleStyle={{ fontSize: '1.2rem', fontWeight: 'bold' }} bubbleStyle={{ border: "1px solid #14427a", background: "#14427a" }} title="Day 10:RETURN FERRY TRANSFERS FOR VENICE ISLAND – GUIDED WALKINGTOUR OF VENICE ISLAND">After continental breakfast at hotel check-out and proceed to Venice, upon arrival you will betreated with the most lavish treat of having the lunch at Indian restaurant, later we drive toand then sail in a special water taxi to the St.Mark’sSquare, which is considered to be one ofthe world’s most beautiful squares with its boutiques, traditional cafes and historic buildings.On your city tour, see incredible sites such as the Bridge of Sighs, the Ducal Palace, St. MarksBasilica and the Clock Tower, which are part of this romantic city. Dinner andovernight stay atthe hotel. </TimelineEvent>
                    </div>
                </Timeline>
                <Timeline className={props.hide} lineColor="#14427a">
                    <div className="timeline">
                        <TimelineEvent className={`${props.hide}`} container="card" cardHeaderStyle={{ background: '#14427a' }} titleStyle={{ fontSize: '1.2rem', fontWeight: 'bold' }} bubbleStyle={{ border: "1px solid #14427a", background: "#14427a" }} title="Day 11: VENICE – LEANING TOWER OF PISA – ROME ">After a continental breakfast at the hotel, check out, sit back and depart to Pisa to visit thehistoric university city of Pisa - famous for its architectural beauty, journey to Pisa will traversethru the wine and olive producing areas of Tuscany. Visit  Leaning Tower of Pisa, theCathedral and the Baptistery of Pisa. The Leaning Tower of Pisais one of the most remarkablearchitectural structures from medieval Europe. Later drive towards Rome. Dinner andOvernight stay in Hotel, Rome.</TimelineEvent>
                    </div>
                </Timeline>
                <Timeline className={props.hide} lineColor="#14427a">
                    <div className="timeline">
                        <TimelineEvent className={`${props.hide}`} container="card" cardHeaderStyle={{ background: '#14427a' }} titleStyle={{ fontSize: '1.2rem', fontWeight: 'bold' }} bubbleStyle={{ border: "1px solid #14427a", background: "#14427a" }} title="Day 12: GUIDE TOUR OF ROME & VATICAN CITY – TIME ELEVATOR SHOW">After a continental breakfast at the hotel, sit back and relax in your coach as we drive toembark on a guided city tour of Rome and witness sights such as the Colosseum, Arc ofConstantine, Roman Forum and Caesar's Forum. Drive past the Venice Square to experiencethe stark contrast between the architecture of the traditional Palazzio Venezia and the modernVictor Emmanuel Monument. Later, visit the Trevi Fountain, which is considered to be the most beautiful fountain of Rome (legend has it that if you make a wish and throw a coin in, yourwish will come true and you will return to Rome) and then later visit Time Elevator entersinto the history of Rome, flies back in time to the legend of Romulus and Remus and thefounding of the city, seeing victories and defeats, development and decline of the RomanEmpire. After the incredible Rome experience, visit the  Vatican City, which is also thesmallest country in the world. Afterwards dinner in hotel and enjoy a comfortable overnightstay in Rome.</TimelineEvent>
                    </div>
                </Timeline>
                <Timeline className={props.hide} lineColor="#14427a">
                    <div className="timeline">
                        <TimelineEvent className={`${props.hide}`} container="card" cardHeaderStyle={{ background: '#14427a' }} titleStyle={{ fontSize: '1.2rem', fontWeight: 'bold' }} bubbleStyle={{ border: "1px solid #14427a", background: "#14427a" }} title="Day 13: ROME – INDIA">After breakfast, bid goodbye to Rome and Europe as you get transferred to the airport in timeto board your flight back home. With an enriching experience of a lifetime we leave with newfriends and happy memories of one of the most beautiful continents on earth.</TimelineEvent>
                    </div>
                </Timeline>
            </div>
            <div className="pd-btn">
                <Button download="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/itinarary%2Feuro.pdf?alt=media&token=f993160d-6a70-4acf-a71b-e3b7028cca7f" />
            </div>
        </div>
    )
}

export default PackageDetails;