import React from 'react'
import PackageDetails from '../PackageDetails'

const Orsang = () => {
    return (
        <div>
            <PackageDetails
                src="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/holidays%2Forsang.jpg?alt=media&token=8aadd1a7-f318-42e8-9846-55ce18365060"
                package="Orsang Camp"
                content1="Early Morning Departure at 05:00 a.m."
                content2="Arrival at Gumandev Temple, Near Jhagadiya at around 7:00 am to 7:30 am."
                content3="Arrival at 10:00 am take entry and enjoy all the activities."
                content4="After the closure of Camp at around 05:00 pm, onward journey to Nilkanth Dham Temple.Arrival at Nilkanth Dham Temple at around 06:00pm. Free time at leisure, Explore the Temple."
                content5="At around 07:30 pm, depart for Surat."
                content6="Arrival at Surat around 12:00 am to 12:30 am."
                hide7="hide7"
                hide8="hide8"
                hide9="hide9"
                hide10="hide10"
                dl="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/itinarary%2Forsang.pdf?alt=media&token=807a67ee-c8e0-4eeb-b754-1b86385458b9"
            />
        </div>
    )
}

export default Orsang;
