import React from 'react'
import firebase from 'firebase/app'
import 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import NumberFormat from 'react-number-format'
import fire from '../../config/fire'
import '../../sass/Hotel.scss'

class Hotel extends React.Component {
    state = {
        name: '',
        number: '',
        dest: '',
        in: '',
        out: ''
    }
    componentDidMount() {
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
            'size': 'invisible',
            'callback': function (response) {
                // reCAPTCHA solved, allow signInWithPhoneNumber.
                this.onClick();
            }
        });
    }

    onClick = (e) => {
        e.preventDefault();

        const phoneNumber = this.state.number;
        //const name = this.state.name;
        const appVerifier = window.recaptchaVerifier;
        fire
            .auth()
            .signInWithPhoneNumber(phoneNumber, appVerifier)
            .then(confirmationResult => {
                // success
                //console.log(confirmationResult)
                window.confirmationResult = confirmationResult;

                const verificationCode = window.prompt('Please enter the OTP code that was sent to your mobile device.');

                confirmationResult.confirm(verificationCode).then(function (result) {
                    // User signed in successfully.
                    // ...
                    //console.log("loged in...")
                    window.confirm('Thanks! You will be contacted shortly');
                }).then(
                    () => {
                        const db = firebase.firestore();

                        db.collection("hotel").add({
                            name: this.state.name,
                            number: this.state.number,
                            dest: this.state.dest,
                            in: this.state.in,
                            out: this.state.out
                        });
                    }
                ).catch(function (error) {
                    // User couldn't sign in (bad verification code?)
                    // ...
                    //console.log(error)
                    window.confirm('Invalid verification code please try again');
                });

            })
            .catch(error => {
                // error
            });
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
        //console.log(e.target.value)
    }

    render() {
        return (
            <div className="Hotel">
                <div className='title' style={{ marginTop: 0 }}>
                    <h3 style={{color: 'white'}}>Hotel Booking</h3>
                </div>
                <form className="form">
                    <div className="group">
                        <div className="form-grp">
                            <label><i class="fa fa-user icon"></i>Name</label>
                            <input type="text" onChange={this.handleChange} name="name" placeholder="Your Full Name" required />
                        </div>
                        <div className="form-grp">
                            <label><i class="fa fa-phone icon"></i>Number</label>
                            <NumberFormat prefix={"+91"} format="+91 #####-#####" onChange={this.handleChange} name="number" placeholder="Enter your Phone Number" required />
                            <span>OTP will be sent on this Number</span>
                        </div>
                    </div>
                    <div className="group">
                        <div className="form-grp">
                            <label><i class="fa fa-map-marker-alt icon"></i>Destination</label>
                            <input type="text" onChange={this.handleChange} value={this.state.dest} name="dest" placeholder="City or Hotel Name" />
                        </div>
                        <div className="form-grp date">
                            <label>Check In</label>
                            <input onChange={this.handleChange} value={this.state.in} name="in"  type="date" />
                        </div>
                        <div className="form-grp date">
                            <label>Check Out</label>
                            <input onChange={this.handleChange} value={this.state.out} name="out"  type="date" />
                        </div>
                    </div>
                    <div id="recaptcha-container"></div>
                    <div className='form-grp'>
                        <button onClick={this.onClick}>Book Now</button>
                    </div>
                </form>
            </div>
        )
    }
}

export default Hotel;