import React from 'react'
import PackageDetails from '../PackageDetails'

const KeralaPD2 = () => {
    return (
        <div>
            <PackageDetails
                src="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/holidays%2FKerala2.jpg?alt=media&token=c20ce810-6660-47fb-8cb1-2cbe849cc259"
                package="Kerala - God's Own Country Packages (7Nights/8Days)"
                day1="Day 01 Arrival at Cochin International Airport/Railway station -Munnar"
                content1="Arrival at Cochin Airport/Railway station. Meet our Representative and Transfer to
Hotel. Cochin also known as &quot;Queen of Arabian Sea&quot; due to the long maritime history;
Arabs, Chinese, Dutch, British and Portuguese followed the sea routes to cochin. The
fascinating Chinese Fishing nets are typical of kochi. Visit Dutch Palace (11km) (Closed
on Fridays), Jewish Synagogue (11 km) (Closed on Saturdays and Jewish holidays), St.
Francis church (15km), Santa Cruz basilica (15 km), Chinese Fishing Nets as time
permits.Overnight stay at the hotel."
                day2="Day 02 Cochin to Munnar"
                content2="After Breakfast check out from Hotel &amp; Proceed to Munnar. Munnar -The so called &quot;tea
garden hill station of kerala&quot;. On the way visit vallara and cheeyappara waterfalls and
Blossom Garden. Evening, enjoy the beauty of tea garden. Overnight stay at the hotel."
                day3="Day 03 Munnar SS"
                content3="Sightseeing of Munnar. Visit Eravikulam National Park (15 km) to view endangered wild
goat &#39; Nilgiri Tahr&#39;.(National Park is Closed From 21st Jan to 15th Apr during breeding
season). Visit Tea Museum (03Km) and the Tea factory (Closed on Mondays and Good
Friday) , Mattupetty Dam (13km) and Kundala Lake (28km).Overnight stay at the hotel.
Note: For visit to  Eravikulam National Park"
                day4="Day 04 Munnar to Thekkady"
                content4="Thekkady is a pleasant heaven on earth for those who love nature in its wild
manifestations. Spices are found in abundance in Periyar. Get ready for a spice tour to
view the plantations of a wide variety of spices like cardamom, pepper, tea, coffee,
cloves, ginger, turmeric, cumin seed, etc. Overnight stay at the hotel."
                day5="Day 05 Thekkady to Alappuzha"
                content5="The &quot;Venice of India&quot; noted for its winding waterways and canals. It is an important
commercial and business town, manufacturing and exporting coir products and black
pepper. Explore the natural beauty of Kerala, the paddy fields, narrow canals, coir
villages etc. Overnight stay at the hotel/ House boat."
                day6="Day 06 Alappuzha to Kovalam"
                content6="Kovalam - breathtakingly beautiful - a haven of peace and tranquillity - the idyllic tourist
destination in God&#39;s own country. Kovalam offers an excellent diversity with Kovalam
beach to suit all desires and occasions. Visit light house beach and Hawah beach.
Overnight stay at the hotel."
                day7="Day 07  Kovalam SS"
                content7="Proceed for sightseeing at Trivandrum. Visit Sree Padmanabhaswamy temple (16 km)
which is 16th century temple dedicated to Lord Vishnu is the most impressive landmark
of the city. Visit Trivandrum Zoo, Sree Chitra Art Gallery, Napier Museum -(16km)
(Closed on Mondays, Wednesday, Govt Holidays, Visiting time :1.00 PM to 4.45 PM),
Science and technology museum (Closed on Mondays) etc. Enjoy sun set in the
evening relaxing on the beach.Overnight stay at the hotel."
                day8="Day 07 Departure transfer"
                content8="After breakfast Transfer to Cochin Airport / Railway station. Back to home with the end of Memorable Trip with Shree Hari Tours & Travels."
                hide9="hide9"
                hide10="hide10"
                dl="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/itinarary%2Fk2.pdf?alt=media&token=111dfcae-23db-4551-afda-24659c889d88"
            />
        </div>
    )
}

export default KeralaPD2;