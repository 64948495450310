import React from 'react'
import '../../../sass/CheckList.scss'
import Button from '../../Layout/Btn'

const aus = () => {
    return (
        <>
            <div className="title">
                <h3>Australia Visa Check List</h3>
            </div>
            <div className="checklist">
                <div className="list">
                    <h4>For Individuals</h4>
                    <ol>
                        <li>Old & New Passport</li>
                        <li>Aadhar Card copy</li>
                        <li>02 New Photographs ( 35x45, 80% face, matte finish with white background)</li>
                        <li>Copy of Pan Card</li>
                        <li>Last 03 Years Income Tax Returns</li>
                        <li>Last 06 Months Updated Savings Bank Statement with Bank Stamp & Sign</li>
                        <li>Net Worth Certificate</li>
                    </ol>
                </div>
                <div className="list">
                    <h4>For Company</h4>
                    <ol>
                        <li>Copy of Pan Card</li>
                        <li>Last 03 Years Income Tax Returns</li>
                        <li>Updated Bank Statement for the Last 6 Months with Bank stamp & sign</li>
                        <li>Company Proof in English (Registration//Partnership Deed//Memorandum)</li>
                        <li>03 Letter Head ( with stamp & sign)</li>
                        <li>Office Address Proof (Light Bill /Telephone Bill)</li>
                    </ol>
                </div>
                <div className="list">
                    <h4>For Employee</h4>
                    <ol>
                        <li>Copy of  Pan Card</li>
                        <li>Last 03 Months Salary Slip on Company Letter Head with Stamp & Sign</li>
                        <li>Updated Bank Statement for the Last 6 Months with Bank stamp & sign (Salary Account)</li>
                        <li>Leave Letter on Company Letter Head with Stamp & Sign</li>
                        <li>Office Id Proof</li>
                    </ol>
                </div>
                <div className="list">
                    <h4>For Sponsor</h4>
                    <ol>
                        <li>Passport Copy / Valid Visa Copy</li>
                        <li>Original Sponsor Letter/Invitation Letter</li>
                        <li>Last 06 months Banks Statement</li>
                        <li>Last 03 years Income Tax Paper</li>
                        <li>Copy of Utility Bill / Residence Proof( Light Bill/ Phone Bill)</li>
                        <li>Employment Proof</li>
                    </ol>
                </div>
            </div>
            <div className="cl-btn">
                <div className="pd-btn">
                    <Button download="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/downloads%2FShree%20Hari%20Tours%20%26%20Travels.pdf?alt=media&token=9fc3418b-5a24-421e-a8c5-7a0d9ad164fa" />
                </div>
            </div>
        </>
    )
}

export default aus;