import React from 'react'
import '../../sass/About.scss'

const About = () => {
    return (
        <div className="about">
            <div className="title">
                <h3>Our Vision</h3>
            </div>
            <div className="para">
                <div className="text">
                    <h5>Choosing the right company to handle your travel arrangements is vitally important.You do need a Tour Operator, who has first hand and deep knowledge of the nuances of travelling. Shree Hari Tours and Travels is a specialist tour company, which will take the time to understand what you would like to see and do on your trip, and work with you towards creating a perfect itinerary and a perfect travel experience for you.</h5>
                </div>                
            </div>
            <div className="title">
                <h3>About Us</h3>
            </div>
            <div className="para">
                {/*<img src="https://lh4.googleusercontent.com/proxy/0lZsSw5zUPjMvuSDZEa59ysM69U84PYoBv-LkI19W9xjiRYBjF7d5k5EWJWR7KjrtWxmaC3gr19EUgDseSxg3WbHaSDW-w-ISWK70lgeZFuZ4Wz2idV1-Ts4h62T" alt="" />*/}
                <div className="text">
                    <h5>Shree Hari Tours and Travels, Founded in the year <b>1993</b> by <b>Mr. Hemant Kosambi</b>, with a vision to provide Best in Class Service in The Field Of Tourism.</h5>
                    <h5>Since our Inception, we are mastered in providing Bus Hire Services to not only Tourism Sector but also Corporate Sector. We also provide services for Picnics, Holiday Packages with Accomodation and Delicious Food cooked by our own Experienced Maharaj’s. We have arranged Tailor made tours for School Picnics, College Picnics, Corporate Picnics etc.</h5>
                </div>
            </div>
            <div className="para1">
                {/*<img src="https://lh4.googleusercontent.com/proxy/0lZsSw5zUPjMvuSDZEa59ysM69U84PYoBv-LkI19W9xjiRYBjF7d5k5EWJWR7KjrtWxmaC3gr19EUgDseSxg3WbHaSDW-w-ISWK70lgeZFuZ4Wz2idV1-Ts4h62T" alt="" />*/}
                <div className="text">
                    <h5>Following the footsteps of <b>Mr. Hemant Kosambi</b>, his Son, <b>Mr. Shaishav Kosambi</b> now manages the firm who has been certified with <b> IATA -Travel And Tourism Consultant (Montreal, Canada), IITC –Diploma in International Airlines and Travel Management, Computerised Reservation System (Amadeus) in the year 2017.</b></h5>
                    <h5>Looking into current market demands, we at Shree Hari Travels are now a <b>“One Stop Shop”</b> for all your travel needs including Air Tickets, Hotel Booking, Visa Assistance, Passport Assistance, Domestic and International Packages, Travel insurance, etc.</h5>
                </div>
            </div>
            <div className="para">
                <div className="text">
                    <h5>Since 1993 we have arranged and handled several thousand tours for our clients, many of whom have travelled more than once with us. We pride ourselves in saying that <b>"We are small enough to care, and large enough to deliver"</b>. We will do everything possible to ensure that your holiday works like clockwork.</h5>
                    <h5>Get in touch with us now, even if you are at the earliest planning stages and only have a vague idea of what you want to see. Allow us to take it forward from there, and see how easy planning and booking your tour is.</h5>
                </div>
            </div>
            <div className="title">
                <h3>Achievement</h3>
            </div>
            <div className="certi">
                <img src="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/images%2FIATA%20Certi.jpeg?alt=media&token=8be53bf8-e6dc-4652-8be7-a2a41855fc06" alt="" />
                <img src="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/images%2FDiploma%20In%20Tourism%20Certi.jpeg?alt=media&token=9c60df08-bac9-4b90-ae2f-7ac72070588a" alt="" />
                <img src="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/images%2FCRS%20Certi.jpeg?alt=media&token=e545749a-addb-44d7-967a-0c5a3961ce30" alt="" />
            </div>
        </div>
    )
}

export default About