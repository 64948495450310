import React from 'react'
import firebase from 'firebase/app'
import 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import NumberFormat from 'react-number-format'
import fire from '../../config/fire'
import '../../sass/Insurance.scss'

class Insurance extends React.Component {
    state = {
        name: '',
        number: '',
        dest: '',
        start: '',
        date: '',
        arrival: ''
    }
    componentDidMount() {
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
            'size': 'invisible',
            'callback': function (response) {
                // reCAPTCHA solved, allow signInWithPhoneNumber.
                this.onClick();
            }
        });
    }

    onClick = (e) => {
        e.preventDefault();

        const phoneNumber = this.state.number;
        //const name = this.state.name;
        const appVerifier = window.recaptchaVerifier;
        fire
            .auth()
            .signInWithPhoneNumber(phoneNumber, appVerifier)
            .then(confirmationResult => {
                // success
                //console.log(confirmationResult)
                window.confirmationResult = confirmationResult;

                const verificationCode = window.prompt('Please enter the OTP code that was sent to your mobile device.');

                confirmationResult.confirm(verificationCode).then(function (result) {
                    // User signed in successfully.
                    // ...
                    //console.log("loged in...")
                    window.confirm('Thanks! You will be contacted shortly');
                }).then(
                    () => {
                        const db = firebase.firestore();

                        db.collection("insurance").add({
                            name: this.state.name,
                            number: this.state.number,
                            dest: this.state.dest,
                            start: this.state.start,
                            date: this.state.date,
                            arrival: this.state.arrival
                        });
                    }
                ).catch(function (error) {
                    // User couldn't sign in (bad verification code?)
                    // ...
                    //console.log(error)
                    window.confirm('Invalid verification code please try again');
                });

            })
            .catch(error => {
                // error
            });
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
        //console.log(e.target.value)
    }
    render() {
        return (
            <>
                <div className="bus">
                    <div className="title">
                        <h3>Travel Insurance</h3>
                        <p>Buying a travel insurance policy is also an investment therefore make the most of it. With Shree Hari Tours & Travels.</p>
                    </div>
                    <div className="bus-form">
                        <form className="b-form">
                            <div className="form-grp">
                                <input type="text" onChange={this.handleChange} name="start" placeholder="From" />
                                <input type="text" onChange={this.handleChange} name="dest" placeholder="Destination" />
                                <input type="date" onChange={this.handleChange} name="arrival" />
                                <input type="date" onChange={this.handleChange} name="date" />
                                <input type="text" onChange={this.handleChange} name="name" placeholder="Your Full Name" required />
                                <NumberFormat prefix={"+91"} format="+91 #####-#####" onChange={this.handleChange} name="number" placeholder="Enter your Phone Number" required />
                                <button className="a" onClick={this.onClick}>Get a Qoute</button>
                                <div id="recaptcha-container"></div>
                            </div>
                        </form>
                    </div>
                </div>
            </>
        )
    }
}

export default Insurance;