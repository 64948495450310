import React from 'react'
import PackageCard from './PackageCard'

const Packages = () => {
    return (
        <div>
            <div className="title">
                <h3>Featured Packages</h3>
                <p>Your Dream tour's are Waiting for you, give us the precious oppourtunity to fullfil it with our well planed tours.</p>
            </div>
            <PackageCard />
        </div>
    )
}

export default Packages;