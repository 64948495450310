import React from 'react'
import Card from '../../Layout/Card'
import '../../../sass/PackageCard.scss'

const Goa = () => {
    return (
        <>
            <div className="title">
                <h3>Goa Tours</h3>
            </div>
            <div className="package-card">
                <div className="card-grp">
                    <Card
                        src="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/holidays%2Fgoa.jpg?alt=media&token=1b4739b6-6cc1-4348-9a27-3c7c002c85bb"
                        title="Goa 3N/4D"
                        button="Explore Now"
                        href="/goaPD1"
                    />
                    <Card
                        src="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/holidays%2Fgoa2.jpg?alt=media&token=b65ebd9c-95a2-4f78-860c-364ae0f0c528"
                        title="Goa 4N/5D"
                        button="Explore Now"
                        href="/goaPD2"
                    />
                </div>
            </div>
        </>
    )
}

export default Goa