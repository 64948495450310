import React from 'react'
import '../../../sass/CheckList.scss'
import Button from '../../Layout/Btn'

const mmr = () => {
    return (
        <>
            <div className="title">
                <h3>Myanmar Visa Checklist (Tourist)</h3>
            </div>
            <div className="checklist">
                <div className="list">
                    <ol>
                        <li>Old & New Passport</li>
                        <li>02 New Photographs (35x45, 80% face on matte finish paper with white background)</li>
                        <li>Updated 06 Months Savings Bank Statement with Bank stamp & sign on every page (Min. Bal Per Person 1,00,000)</li>
                        <li>Hotel Confirmation/Invitation Letter</li>
                        <li>02 Company Letter Head with Stamp & Sign</li>
                        <li>Confirmed Return Ticket</li>
                    </ol>
                </div>
            </div>
            <div className="cl-btn">
                <div className="pd-btn">
                    <Button download="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/downloads%2F14.pdf?alt=media&token=dfe528cd-254c-4e2f-a439-acfed84b4925" />
                </div>
            </div>
        </>
    )
}

export default mmr;