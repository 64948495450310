import React from 'react'
import '../../sass/Reviews.scss'

const Reviews = props => {
    return (
        <div className="reviews">
            <div className="avatar">
                <img src={`https://ui-avatars.com/api/?background=0D8ABC&color=fff&name=${props.name}`} alt="avatar" />
            </div>
            <div className="review">
                <h3>{props.name}</h3>
                <div className="rating">
                    <span className={`fa fa-star ${props.star1}`}></span>
                    <span className={`fa fa-star ${props.star2}`}></span>
                    <span className={`fa fa-star ${props.star3}`}></span>
                    <span className={`fa fa-star ${props.star4}`}></span>
                    <span className={`fa fa-star ${props.star5}`}></span>
                </div>
                <p>{props.comment}</p>
            </div>
        </div>
    )
}

export default Reviews;
