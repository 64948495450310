import React from 'react'
import '../../../sass/CheckList.scss'
import Button from '../../Layout/Btn'

const moz = () => {
    return (
        <>
            <div className="title">
                <h3>Mozambique Visa Checklist (Business)</h3>
            </div>
            <div className="checklist">
                <div className="list">
                    <h4>For Individual</h4>
                    <ol>
                        <li>Original Passport (Old & New)</li>
                        <li>02 Photographs (35x45, 80% Face, matt finish with white background without Spectacles)</li>
                        <li>Copy of Pan Card</li>
                        <li>Original Updated 06 months bank Statement with bank stamp & sign</li>
                        <li>Invitation letter from Mozambique attested by the Immigration department with ID Proof of Invitee</li>
                        <li>Original Yellow Fever Certificate</li>
                        <li>02 Company Letter Head with Stamp & Sign</li>
                        <li>Confirmed Return Air Tickets</li>
                    </ol>
                </div>
            </div>
            <div className="cl-btn">
                <div className="pd-btn">
                    <Button download="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/downloads%2F13.pdf?alt=media&token=550fbddb-219a-4cd4-9d52-77f93dfafc52" />
                </div>
            </div>
        </>
    )
}

export default moz