import React from 'react'
import PackageDetails from '../PackageDetails'

const GoaPD1 = () => {
    return (
        <div>
            <PackageDetails
                src="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/holidays%2Fgoa.jpg?alt=media&token=1b4739b6-6cc1-4348-9a27-3c7c002c85bb"
                package="GOA 3N/4D Package"
                day1="Day 01"
                content1="Arrival at Goa(Airport/Thivim Railway station). Meet our representative and transfer to Hotel. After Refreshments, full day at Leisure. Overnight Stay at Hotel."
                day2="Day 02"
                content2="After Breakfast, We will head towards North Goa Sightseeing, visit various places like Anjuna Beach, Vagator Beach, Baga Beach, Siquerim Beach. Later visit to Fort Aguada, Calangute Beach. Overnight Stay at Hotel."
                day3="Day 03"
                content3="After Breakfast, we will proceed to South Goa Sightseeing, visit Old Goa Churches, Mangeshi Temple, Dona Paula, Miramar Beach. Later time for Shopping. Boat Cruise in the evening(Optional tour) / Casino (Optional)."
                day4="Day 04"
                content4="After Breakfast, Check out from hotel. Transfer to Airport/Thivim Railway Station, with the end of Memorable Trip with Shree Hari Tours & Travels."
                hide5="hide5"
                hide6="hide6"
                hide7="hide7"
                hide8="hide8"
                hide9="hide9"
                hide10="hide10"
                dl="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/itinarary%2Fg1.pdf?alt=media&token=199e3fd1-443b-4b3f-a78b-eb3448330f81"
            />
        </div>
    )
}

export default GoaPD1;
