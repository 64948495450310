import React from 'react'
import Card from '../../Layout/Card'
import '../../../sass/PackageCard.scss'

const Sou = () => {
    return (
        <>
            <div className="title">
                <h3>Statue of Unity Tours</h3>
            </div>
            <div className="package-card">
                <div className="card-grp">
                    <Card
                        src="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/holidays%2Fsou.webp?alt=media&token=48c2ea69-f8b9-4511-89fd-01873becd577"
                        title="Statue Of Unity (One Day Trip)"
                        button="Explore Now"
                        href="/souPD1"
                    />
                    <Card
                        src="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/holidays%2Fsou2.jpg?alt=media&token=ec9239cd-424d-4cf7-a808-6940d263a1fe"
                        title="Statue of Unity (Tent stay)"
                        button="Explore Now"
                        href="/souPD2"
                    />
                </div>
            </div>
        </>
    )
}

export default Sou