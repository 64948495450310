import React from 'react'
import { Timeline, TimelineEvent } from 'react-event-timeline'
import '../../sass/Itinarary.scss'

const Itinarary = props => {
    return (
        <div>
            <Timeline className={props.hide} lineColor="#14427a">
                <div className="timeline">
                    <TimelineEvent className={`${props.hide}`} container="card" cardHeaderStyle={{ background: '#14427a' }} titleStyle={{ fontSize: '1.2rem', fontWeight: 'bold' }} bubbleStyle={{ border: "1px solid #14427a", background: "#14427a" }} title={props.title}>{props.content}</TimelineEvent>
                </div>
            </Timeline>
        </div>
    )
}

export default Itinarary;