import React from 'react'
import '../../../sass/CheckList.scss'
import Button from '../../Layout/Btn'

const mys = () => {
    return (
        <>
            <div className="title">
                <h3>Malaysia Visa check List (Tourist)</h3>
            </div>
            <div className="checklist">
                <div className="list">
                    <h4>For Individual</h4>
                    <ol>
                        <li>Current Passport</li>
                        <li>02 Photographs (35x50, 70% Face, matt finish with white background)</li>
                        <li>Last 03 Months Updated Bank Statement with Bank Stamp & Sign (Min. Bal. 60,000 Per Person)</li>
                        <li>02 Blank Letter Head with Stamp & Sign</li>
                        <li>Hotel confirmation</li>
                        <li>Confirmed Return Tickets</li>
                        <li>02 Company Letter Head with Stamp & Sign/ Visiting Card (Occupation Proof)</li>
                    </ol>
                </div>
                <div className="list">
                    <h4>For Honeymooners</h4>
                    <ol>
                        <li>Marriage Certificate / Marriage invitation Card & Marriage Photographs</li>
                        <li>NOC Letter from Parents with Passport Copies / Id Proof</li>
                    </ol>
                </div>
                <div className="list">
                    <h4>For Minor/Student</h4>
                    <ol>
                        <li>School /College Id Proof with Address</li>
                    </ol>
                </div>
            </div>
            <div className="cl-btn">
                <div className="pd-btn">
                    <Button download="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/downloads%2F10.pdf?alt=media&token=32d39011-66ad-462d-8000-dd6efae8a4dc" />
                </div>
            </div>
        </>
    )
}

export default mys;
