import React from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import '../../sass/NavBar.scss'

const NavBar = () => {
    return (
        <div className="NavBar">
            <Navbar collapseOnSelect sticky="top" expand="lg" bg="light" variant="light">
                <Navbar.Brand href="/">
                    <div className="brand">
                        <img
                            alt=""
                            src="./logo.png"
                        />
                    </div>
                    <span>Shree Hari Tours & Travels</span>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav" className="">
                    <Nav className="navbar-nav ml-auto">
                        <Nav.Link href="/">Home</Nav.Link>
                        <Nav.Link href="/flights">Flight</Nav.Link>
                        <Nav.Link href="/holidays">Holidays</Nav.Link>
                        <Nav.Link href="/visa">Visa</Nav.Link>
                        <Nav.Link href="/about">About Us</Nav.Link>
                        <Nav.Link href="/contact">Contact Us</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </div>
    );
}

export default NavBar;