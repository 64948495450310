import React from 'react'
import '../../../sass/CheckList.scss'
import Button from '../../Layout/Btn'

const jpn = () => {
    return (
        <>
            <div className="title">
                <h3>Japan Visa check List (Tourist)</h3>
            </div>
            <div className="checklist">
                <div className="list">
                    <h4>For Individual</h4>
                    <ol>
                        <li>Old & New Passport</li>
                        <li>02 photographs(45mm x 45mm, 80% face,matte finish,white background Without spectacles)</li>
                        <li>Copy of Pan Card</li>
                        <li>Last 03 years Income Tax Returns</li>
                        <li>Last 03 Months Updated Bank Statement with Bank Stamp & Sign (Min. Bal. 1,50,000 Per Person)</li>
                        <li>02 Blank Letter Head with Stamp & Sign</li>
                        <li>Hotel confirmation with Day to Day Itinerary</li>
                        <li>Confirmed Return Tickets</li>
                    </ol>
                </div>
                <div className="list">
                    <h4>For Employee</h4>
                    <ol>
                        <li>Last 03 Months Salary Slips</li>
                        <li>Leave Sanctioned Letter from Company with stamp & sign</li>
                    </ol>
                </div>
            </div>
            <div className="cl-btn">
                <div className="pd-btn">
                    <Button download="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/downloads%2F10.pdf?alt=media&token=32d39011-66ad-462d-8000-dd6efae8a4dc" />
                </div>
            </div>
        </>
    )
}

export default jpn;
