import React from 'react'
import '../../../sass/CheckList.scss'
import Button from '../../Layout/Btn'

const nzl = () => {
    return (
        <>
            <div className="title">
                <h3>New Zealand Visa Checklist (Tourist)</h3>
            </div>
            <div className="checklist">
                <div className="list">
                    <h4>For Individuals</h4>
                    <ol>
                        <li>Old & New Passport</li>
                        <li>02 New Photographs ( 35x45, 80% face, matte finish with white background, Without spectacles)</li>
                        <li>Copy of Pan Card</li>
                        <li>Last 03 Years Income Tax Returns</li>
                        <li>Last 03 Months Updated Bank Statement with Bank Stamp & Sign (Min. Bal. 2 Lacks per person)</li>
                        <li>Return Ticket</li>
                        <li>Residential proof (Light Bill/Phone Bill)</li>
                    </ol>
                </div>
                <div className="list">
                    <h4>For Company</h4>
                    <ol>
                        <li>Copy of Pan Card</li>
                        <li>Last 03 Years Income Tax Returns</li>
                        <li>Updated Bank Statement for the Last 3 Months with Bank stamp & sign</li>
                        <li>Company Proof in English (Registration//Partnership Deed//Memorandum)</li>
                        <li>03 Letter Head ( with stamp & sign)</li>
                        <li>Office Address Proof (Light Bill /Telephone Bill)</li>
                    </ol>
                </div>
                <div className="list">
                    <h4>For Employee</h4>
                    <ol>
                        <li>Copy of  Pan Card</li>
                        <li>Last 03 Months Salary Slip on Company Letter Head with Stamp & Sign</li>
                        <li>Updated Bank Statement for the Last 3 Months with Bank stamp & sign</li>
                        <li>Leave Letter on Company Letter Head with Stamp & Sign</li>
                        <li>Employment Id Proof</li>
                    </ol>
                </div>
                <div className="list">
                    <h4>For Sponsor</h4>
                    <ol>
                        <li>Passport Copy / Valid Visa Copy</li>
                        <li>Sponsor Letter/Invitation Letter</li>
                        <li>Last 03 months Banks Statement</li>
                        <li>Last 03 years Income Tax Returns</li>
                        <li>Copy of Utility Bill / Residence Proof( Light Bill/ Phone Bill)</li>
                        <li>Employment Id Proof</li>
                        <li>Sponsorship Form 1025</li>
                    </ol>
                </div>
            </div>
            <div className="cl-btn">
                <div className="pd-btn">
                    <Button download="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/downloads%2F15.pdf?alt=media&token=e4f8c6c7-044f-4d1b-bce6-da263cb83bf0" />
                </div>
            </div>
        </>
    )
}

export default nzl