import React from 'react'
import { Timeline, TimelineEvent } from 'react-event-timeline'
import '../../../sass/Itinarary.scss'
import '../../../sass/PackageDetails.scss'
import Button from '../../Layout/Button'

const PackageDetails = props => {
    return (
        <div className="package-details">
            <img src="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/holidays%2FChardham.jpg?alt=media&token=c2a61fcb-ab75-43a8-8371-0f5163db9741" alt="" />
            <div className="title">
                <h3>Char Dham 12 Days / 11 Nights </h3>
            </div>
            <div className="">
                <div className="title" style={{ textAlign: 'left', color: '#777' }}>
                    <h3>Itinarary</h3>
                </div>
                <Timeline className={props.hide} lineColor="#14427a">
                    <div className="timeline">
                        <TimelineEvent className={`${props.hide}`} container="card" cardHeaderStyle={{ background: '#14427a' }} titleStyle={{ fontSize: '1.2rem', fontWeight: 'bold' }} bubbleStyle={{ border: "1px solid #14427a", background: "#14427a" }} title="Day 01: Delhi to Haridwar">Start your drive to Haridwar form Delhi. On your way to Haridwar, get your Biometric test done for theYatra. On reaching Haridwar, check-in to your hotel. In the evening, visit the Har- Ki- Pauri to attend thegrand Ganga Aarti on the banks of Ganges. Later, return to the hotel for dinner and have a comfortable stayat night</TimelineEvent>
                    </div>
                </Timeline>
                <Timeline className={props.hide} lineColor="#14427a">
                    <div className="timeline">
                        <TimelineEvent className={`${props.hide}`} container="card" cardHeaderStyle={{ background: '#14427a' }} titleStyle={{ fontSize: '1.2rem', fontWeight: 'bold' }} bubbleStyle={{ border: "1px solid #14427a", background: "#14427a" }} title="Day 02: Haridwar to Barkot / Kharadi">This morning, you depart for Barkot / Kharadi. Barkot is a beautiful hill station which is located on the footof Yamunotri. On arrival at Barkot, check-in to your hotel/camp. Rest of the day is free to relax and storeyour energy for the Pahad (HiII) Yatra of Yamunotri the next day. Dinner and Overnight stay at Barkot</TimelineEvent>
                    </div>
                </Timeline>
                <Timeline className={props.hide} lineColor="#14427a">
                    <div className="timeline">
                        <TimelineEvent className={`${props.hide}`} container="card" cardHeaderStyle={{ background: '#14427a' }} titleStyle={{ fontSize: '1.2rem', fontWeight: 'bold' }} bubbleStyle={{ border: "1px solid #14427a", background: "#14427a" }} title="Day 03: Barkot / Kharadi – to Janaki Chatti – Barkot / Kharadi">After breakfast, depart for Hanumanchatti (40 Km), Janaki Chatti (8 km). Here you will begin the First PahadYatra of Yamunotri (6 Km trek). You have an option of hiring palki or a horse for your trek. (Cost NotIncluded). The trek passes through lush green valley, a profusion of conifers, rhododendrons, cacti andseveral species of Himalayan shrubs. Yamunotri (altitude 3,291 m), the western most shrine of the CharDhams, is snuggled in the Banderpunch range (6,361 m). Yamunotri is the source of the river Yamuna, thetwin sister of Varna, the Lord of Death. Yamuna is also the daughter of Surya the Sun. The actual source ofthe river originates from Champasar Glacier 1 km a head of the shrine, at an altitude of 4,321 mts. Onarrival by afternoon in Yamunotri take holy dip in Yamuna River and perform Pooja.  Return via the sameroute back to Barkot, check in at Hotel / Camp. Dinner and Overnight stay at hotel in Barkot</TimelineEvent>
                    </div>
                </Timeline>
                <Timeline className={props.hide} lineColor="#14427a">
                    <div className="timeline">
                        <TimelineEvent className={`${props.hide}`} container="card" cardHeaderStyle={{ background: '#14427a' }} titleStyle={{ fontSize: '1.2rem', fontWeight: 'bold' }} bubbleStyle={{ border: "1px solid #14427a", background: "#14427a" }} title="Day 04: Barkot / Kharadi – to Uttarkashi">After breakfast check out from the Barkot hotel and drive to Uttarkashi. On arrival check in into the hotel.Uttarkashi is situated on the banks of river Bhagirathi and is famous for its historical monuments, Temples& Ashrams. In the evening visit famous Vishwnath Temple, Dinner and Overnight at Uttarkashi.</TimelineEvent>
                    </div>
                </Timeline>
                <Timeline className={props.hide} lineColor="#14427a">
                    <div className="timeline">
                        <TimelineEvent className={`${props.hide}`} container="card" cardHeaderStyle={{ background: '#14427a' }} titleStyle={{ fontSize: '1.2rem', fontWeight: 'bold' }} bubbleStyle={{ border: "1px solid #14427a", background: "#14427a" }} title="Day 05: Uttarkashi – Gangotri – Uttarkashi">Early morning breakfast at hotel and drive to Gangotri. Nestled in the magnificent Garhwal Himalayas,Gangotri is set at an altitude of 3048 m. Upon arrival at Gangotri take a holy dip in the sacred river Gangeswhich is also called Bhagirathi at its origin. Visit the Gangotri Temple. The 18th century’s temple dedicatedto Goddess Ganga is located near a sacred stone where King Bhagirathi worshipped Lord Shiva. Ganga isbelieved to have touched earth at this spot. The Temple is an exquisite 20ft. high structure mad of whitegranite. After performing Pooja Late afternoon drive back to Uttarkashi. Dinner & overnight stay atUttarkashi.</TimelineEvent>
                    </div>
                </Timeline>
                <Timeline className={props.hide} lineColor="#14427a">
                    <div className="timeline">
                        <TimelineEvent className={`${props.hide}`} container="card" cardHeaderStyle={{ background: '#14427a' }} titleStyle={{ fontSize: '1.2rem', fontWeight: 'bold' }} bubbleStyle={{ border: "1px solid #14427a", background: "#14427a" }} title="Day 06: Uttarkashi – Guptkashi">Leave Resort for your next destination, Guptkashi after having your breakfast early in the morning (06:00 am). On reaching, check-in to the hotel. Guptkashi is located at a distance of 47 km from the holy shrine, Kedarnath. The town holds immense religious importance as it houses famous ancient temples like Vishwnath Temple and Ardhnareshwar Temple. Get your medical checkup done for your next day’s journey to Kedarnath. Have your dinner early and get sleep in the hotel/camp, because today midnight (01:00 am) you have to leave the hotel for Kedarnath.</TimelineEvent>
                    </div>
                </Timeline>
                <Timeline className={props.hide} lineColor="#14427a">
                    <div className="timeline">
                        <TimelineEvent className={`${props.hide}`} container="card" cardHeaderStyle={{ background: '#14427a' }} titleStyle={{ fontSize: '1.2rem', fontWeight: 'bold' }} bubbleStyle={{ border: "1px solid #14427a", background: "#14427a" }} title="Day 07: Guptkashi - Kedarnath - Guptkashi">Early morning (01:00 am) with dry breakfast (packing) check out from the hotel and drive to Sonprayag. From where, you will start your 20 km trek to Kedarnath. You can hire a Doli or a horse, for your trek (Cost Not Included). Mandakini, one of the main tributaries of the Ganges originates at Kedarnath and flows through Gaurikund. "Jai Bholenath" chants echoes in the mountains. On arrival at Kedarnath Complete your Darashan & Pooja Archna & Return back to Sonprayag via Gaurikund, on arrive contact our representative at Sonprayag Barial for next arrangement. </TimelineEvent>
                    </div>
                </Timeline>
                <Timeline className={props.hide} lineColor="#14427a">
                    <div className="timeline">
                        <TimelineEvent className={`${props.hide}`} container="card" cardHeaderStyle={{ background: '#14427a' }} titleStyle={{ fontSize: '1.2rem', fontWeight: 'bold' }} bubbleStyle={{ border: "1px solid #14427a", background: "#14427a" }} title="Day 08: Guptkashi – Pipalkot ">After having your breakfast in the morning, check out of the hotel and start driving towards your next destination Pipalkoti via Chopta - Dinner & overnight in Pipalkoti.</TimelineEvent>
                    </div>
                </Timeline>
                <Timeline className={props.hide} lineColor="#14427a">
                    <div className="timeline">
                        <TimelineEvent className={`${props.hide}`} container="card" cardHeaderStyle={{ background: '#14427a' }} titleStyle={{ fontSize: '1.2rem', fontWeight: 'bold' }} bubbleStyle={{ border: "1px solid #14427a", background: "#14427a" }} title="Day 09: Pipalkoti– Badrinath – Pipalkoti">Today Early Morning breakfast we drive for Badrinath is an important pilgrimage site which holds immense importance for both Hindus and Buddhists. Badrinath temple is dedicated to Lord Vishnu and is set at a height of 3133 meter. On arrive first you have to go to Tapt Kund (Hot Spring), take bath and then go to the temple. And then after perform Darshan & Pooja. After Some light Lunch, you can leave for local sightseeing at Badrinath. Visit major attractions of Badrinath such as Mana Village, Vyas Gufa, Ganesh Gufa, and Mata Murti temple. Evening back at Pipalkot / Joshimath, Dinner & overnight in Pipalkot / Joshimath</TimelineEvent>
                    </div>
                </Timeline>
                <Timeline className={props.hide} lineColor="#14427a">
                    <div className="timeline">
                        <TimelineEvent className={`${props.hide}`} container="card" cardHeaderStyle={{ background: '#14427a' }} titleStyle={{ fontSize: '1.2rem', fontWeight: 'bold' }} bubbleStyle={{ border: "1px solid #14427a", background: "#14427a" }} title="Day 10: Pipalkoti - Haridwar">Early morning, after breakfast, you drive downhill to Haridwar, On arrival check in at hotel. Dinner & Overnight at hotel in Haridwar. </TimelineEvent>
                    </div>
                </Timeline>
                <Timeline className={props.hide} lineColor="#14427a">
                    <div className="timeline">
                        <TimelineEvent className={`${props.hide}`} container="card" cardHeaderStyle={{ background: '#14427a' }} titleStyle={{ fontSize: '1.2rem', fontWeight: 'bold' }} bubbleStyle={{ border: "1px solid #14427a", background: "#14427a" }} title="Day 11: Haridwar ">Today after breakfast your free for leisure and shopping at Har ki Pori Market and free for perform Pooja Archana & Pitru Tarpan Vidhi at Har ki Pori Ghat at bank of Ganga,& visit Rishikesh a spiritual city and the Yoga capital of the world. On reaching Rishikesh, go out for sightseeing. Visit Ram Jhula & Laxman Jhula. Evening drive to Haridwar Dinner at hotel & overnight stay at hotel in Haridwar.</TimelineEvent>
                    </div>
                </Timeline>
                <Timeline className={props.hide} lineColor="#14427a">
                    <div className="timeline">
                        <TimelineEvent className={`${props.hide}`} container="card" cardHeaderStyle={{ background: '#14427a' }} titleStyle={{ fontSize: '1.2rem', fontWeight: 'bold' }} bubbleStyle={{ border: "1px solid #14427a", background: "#14427a" }} title="Day 12:Haridwar - Delhi">After Morning breakfast at hotel, and you are on your last lap of this Chardham Yatra Journey. Drive back to Delhi. On reaching Delhi transfer to Railway Station, with end of memorable trip by Shree Hari Tours & Travels.</TimelineEvent>
                    </div>
                </Timeline>
            </div>
            <div className="pd-btn">
                <Button download="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/itinarary%2Fcdy.pdf?alt=media&token=450ee7e2-642e-465c-bd3b-3c44d20584fc"/>
            </div>
        </div>
    )
}

export default PackageDetails;