import React from 'react'
import '../../sass/Footer.scss'
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <div className="footer">
            <div className="social">
                <a href="https://www.facebook.com/Shree-Hari-Tours-Travels-103636494340323/" target="_blank" rel="noopener noreferrer"><div className="fa fa-facebook"></div></a>
                <a href="https://www.instagram.com/shree_hari_travels_surat/" target="_blank" rel="noopener noreferrer"><div className="fa fa-instagram"></div></a>
                <a href="https://twitter.com/HariTours" target="_blank" rel="noopener noreferrer"><div className="fa fa-twitter"></div></a>
                <a href="https://www.linkedin.com/in/shree-hari-travels-415a22124" target="_blank" rel="noopener noreferrer"><div className="fa fa-linkedin"></div></a>
            </div>
            <div className="links">
                <div className="ul">
                    <ul>
                        <Link to="/privacy"><li>Privacy </li></Link>
                        <Link to="/about"><li>About Us</li></Link>
                        <Link to="/contact"><li>Contact Us</li></Link>
                    </ul>
                    <ul>
                        <Link to="/flights"><li>Flight Booking</li></Link>
                        <Link to="/contact"><li>Personalized Tour</li></Link>
                        <Link to="/visa"><li>Visa</li></Link>
                        <Link to="/bus"><li>Bus Booking</li></Link>
                        <Link to="/car"><li>Car Rental</li></Link>
                    </ul>
                    <ul>
                        <p>Silver Plaza Complex, UF-7, Rander Rd, opp. RUPALI CINEMA, Palanpur Patia, Surat, Gujarat 395009</p>
                        <p>+91 75678 37173 | 94261 07637 | 70690 29095</p>
                        <p><a href="mailto:shreeharitravelssurat@gmail.com">shreeharitravelssurat@gmail.com</a></p>
                    </ul>
                </div>
            </div>
            <div className="copy">
                <p>&copy; {new Date().getFullYear()} Shree Hari Tours & Travels, All Rights Reserved.</p>
                <p>Developed with <span style={{color: 'red'}}>❤</span> by <a href="https://nimitpatel.dev" target="_blank" rel="noopener noreferrer">Nimit Patel</a></p>
            </div>
        </div>
    )
}

export default Footer;
