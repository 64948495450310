import React from 'react'
import '../../../sass/CheckList.scss'
import Button from '../../Layout/Btn'

const hrv = () => {
    return (
        <>
            <div className="title">
                <h3>Croatia Visa check List (Tourist)</h3>
            </div>
            <div className="checklist">
                <div className="list">
                    <h4>Personal</h4>
                    <ol>
                        <li>All Passports</li>
                        <li>02 photographs(35 x 45, 80% face,mattfinish,whiteback groundWithout spectacles)</li>
                        <li>Copy of Pan Card</li>
                        <li>Last 03 Years Income Tax Returns</li>
                        <li>Last 06 Months Updated Savings Bank Statement with Bank Stamp & Sign</li>
                        <li>Savings/ Fixed deposits/LIC Copies (Ifany)</li>
                        <li>Residence Proof  ( Light/phone Bill)</li>
                        <li>Foreign Exchange Receipt (700 Euro) / Credit Card Statement  with Credit Card Copy</li>
                        <li>Hotel Confirmation</li>
                        <li>Return Flight Tickets</li>
                        <li>Travel Insurance</li>
                    </ol>
                </div>
                <div className="list">
                    <h4>Firm</h4>
                    <ol>
                        <li>Copy of  Pan Card</li>
                        <li>Last 03 Years Income Tax Returns</li>
                        <li>Updated Bank Statement for the Last 6 Months with Bank stamp & sign</li>
                        <li>Company Proof in English (Registration//Partnership Deed//Memorandum)</li>
                        <li>03 Letter Head ( with stamp & sign)</li>
                        <li>Office Address Proof (Light Bill /Telephone Bill)</li>
                    </ol>
                </div>
                <div className="list">
                    <h4>Employee</h4>
                    <ol>
                        <li>Copy of  Pan Card</li>
                        <li>Last 03 Years Income Tax Returns</li>
                        <li>Last 03 Months Salary Slip on Company Letter Head with Stamp & Sign</li>
                        <li>Updated Bank Statement for the Last 6 Months with Bank stamp & sign (Salary Account)</li>
                        <li>Leave Letter on Company Letter Head with Stamp & Sign</li>
                        <li>Office Id Proof</li>
                    </ol>
                </div>
                <div className="list">
                    <h4>Sponsor</h4>
                    <ol>
                        <li>Passport Copy / Valid Visa Copy</li>
                        <li>Original Sponsor Letter/Invitation Letter</li>
                        <li>Last 06 months Banks Statement</li>
                        <li>Last 03 years Income Tax Paper</li>
                        <li>Copy of Utility Bill / Residence Proof( Light Bill/ Phone Bill)</li>
                    </ol>
                </div>
            </div>
            <div className="cl-btn">
                <div className="pd-btn">
                    <Button download="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/downloads%2F21.pdf?alt=media&token=0431164c-0a4a-49ac-8e8d-1760e87204c7" />
                </div>
            </div>
        </>
    )
}

export default hrv;