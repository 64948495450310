import React from 'react'
import Card from '../Layout/Card'
import '../../sass/PackageCard.scss'

const PackageCard = () => {
    return (
        <div className="package-card">
            <div className="card-grp">
                <Card
                    src="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/holidays%2Fbangkok.jpg?alt=media&token=9f910341-ae1b-457e-9984-8b83d870193f"
                    title="Thailand"
                    button="Explore Now"
                    href="/thailand"
                />
                <Card
                    src="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/holidays%2Feurope.jpg?alt=media&token=75a0aeb1-e507-4f7d-bd83-8f4a9ed5da86"
                    title="Europe"
                    button="Explore Now"
                    href="/eur"
                />
                <Card
                    src="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/holidays%2Fandaman.jpg?alt=media&token=8fdc6f33-bc73-4169-8e47-09df4991e1b7"
                    title="Andamans"
                    button="Explore Now"
                    href="/and"
                />
                <Card
                    src="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/holidays%2FChardham.jpg?alt=media&token=c2a61fcb-ab75-43a8-8371-0f5163db9741"
                    title="Char Dham"
                    button="Explore Now"
                    href="/cdy"
                />
                <Card
                    src="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/holidays%2Fgoa.jpg?alt=media&token=1b4739b6-6cc1-4348-9a27-3c7c002c85bb"
                    title="Goa"
                    button="Explore Now"
                    href="/goa"
                />
                <Card
                    src="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/holidays%2FKerala.jpg?alt=media&token=4ef57747-3b8f-4ad5-9b89-849fb870cf0c"
                    title="Kerala"
                    button="Explore Now"
                    href="/kerala"
                />
                <Card
                    src="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/holidays%2Fsou.webp?alt=media&token=48c2ea69-f8b9-4511-89fd-01873becd577"
                    title="Statue of Unity"
                    button="Explore Now"
                    href="/sou"
                />
                <Card
                    src="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/holidays%2Fsaputara.jpg?alt=media&token=ed3c3e02-f923-4b31-adc2-002c23960bd0"
                    title="Saputara"
                    button="Explore Now"
                    href="/saputara"
                />
                <Card
                    src="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/holidays%2FDudhani.jpeg?alt=media&token=3e8609f0-d0ea-400b-bd07-7c7bce49d331"
                    title="Silvassa & Dudhani"
                    button="Explore Now"
                    href="/sdk"
                />
                <Card
                    src="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/holidays%2Forsang.jpg?alt=media&token=8aadd1a7-f318-42e8-9846-55ce18365060"
                    title="Orsang Camp"
                    button="Explore Now"
                    href="/orsang"
                />
                <Card
                    src="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/holidays%2Fcostalspander.jpg?alt=media&token=b9a1fb7b-d8bb-4714-b4d4-ec3e97399a1c"
                    title="Costal Splendors"
                    button="Explore Now"
                    href="/cst"
                />
                <Card
                    src="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/holidays%2Fhimachal.webp?alt=media&token=c9db03a1-116d-40cd-a40d-77ab8900097c"
                    title="Himachal"
                    button="Explore Now"
                    href="/hima"
                />
                <Card
                    src="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/holidays%2Frann.jpg?alt=media&token=15489ed5-d934-4838-967d-eef23ad6c3b5"
                    title="Rann Utsav"
                    button="Explore Now"
                />
                <Card
                    src="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/holidays%2FSomnath.jpg?alt=media&token=aaf51d43-ed6d-4c73-a3b2-3a666bf25ad9"
                    title="Saurashtra"
                    button="Explore Now"
                />
            </div>
        </div>
    )
}

export default PackageCard;