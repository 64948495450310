import React from 'react'
import Card from '../../Layout/Card'
import '../../../sass/PackageCard.scss'

const Kerala = () => {
    return (
        <>
            <div className="title">
                <h3>Kerala Tours</h3>
            </div>
            <div className="package-card">
                <div className="card-grp">
                    <Card
                        src="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/holidays%2FKerala.jpg?alt=media&token=4ef57747-3b8f-4ad5-9b89-849fb870cf0c"
                        title="Kerala 6N/7D"
                        button="Explore Now"
                        href="/keralapd1"
                    />
                    <Card
                        src="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/holidays%2FKerala2.jpg?alt=media&token=c20ce810-6660-47fb-8cb1-2cbe849cc259"
                        title="Kerala 7N/8D"
                        button="Explore Now"
                        href="/keralapd2"
                    />
                </div>
            </div>
        </>
    )
}

export default Kerala