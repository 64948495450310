import React from 'react'
import PackageDetails from '../PackageDetails'

const Thailand = () => {
    return (
        <div>
            <PackageDetails
                src="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/holidays%2Fbangkok.jpg?alt=media&token=9f910341-ae1b-457e-9984-8b83d870193f"
                package="Thailand"
                day1="Day 01 Bangkok: Arrival, Proceed to Pattaya"
                content1="On arrival at Savaranbhumi International Airport, complete your immigration formalities and proceed to arrival hall, our tour representative will receive you and assist your to board your transfer to Pattaya as per scheduled itinerary. On arrival at Pattaya hotel, complete your check-in formalities (STD check-in time is 14:00 Hrs.) Time free for freshen up. In the evening get ready to enjoy Alcazar Show, a dazzling show with energetic acts, bright colors and extravagant costumes, the Alcazar show is an entertaining evening for people of all ages. The show takes you through different countries and their cultures. During the 60- 70minutes show you will see a good variety of acts including Korean K-Pop, Persian, Chinese, Indian, Thai and Russian. After show return to Hotel. Overnight Comfortable stay in the hotel."
                day2="Day 02 Pattaya – Coral Island"
                content2="After having breakfast in hotel restaurant. Get ready to enjoyCoral Island Tour in Pattaya– also Known as Koh Larn is an awesome Island just a 45 minutes speed boat ride from Pattaya Pier. Tour offer you opportunity for Sun bath, swimming in the ocean, Parasailing, Under Sea Walk, Jet Ski, Banana Boat and Sand Scooter Ride etc. All water sports is payable by the tourist as per actual directly there. In the afternoon board your Speed boat to Pattaya Pier and get transfer to Indian restaurant for Lunch and drop at hotel.Evening free to enjoy the night life of Pattaya with the variety of Pubs, Discotheque and Bar etc. Overnight comfortable stay in hotel."
                day3="Day 03 Pattaya – Free at leisure."
                content3="After havening delicious breakfast in hotel restaurant. Day free at leisure or you can explore Pattaya at your own. Or can add optional tours like Noong Noonch Garden/ Sri Racha Tiger Zoo/ Art Of Paradise etc. Overnight comfortable stay in hotel."
                day4="Day 04 Pattaya – Bangkok"
                content4="After having delicious breakfast in hotel restaurant. Collect your luggage and checkout from hotel. Complete the checkout formalities on hotel reception and clear your dues (In room chargeable services & minibar bill) if any. Start your onward journey to Bangkok with a unique experience of Gems Gallery Tour which unfolds the story of precious gems being sourced, cutting, shaping, polishing and being made available for you from various regions and countries. Toy train awaits you for a ride through this amazing gems stones journey. Enjoy your afternoon exploring the rich heritage of Bangkok and its culture. Visit the Golden Buddha Temple (Wat Tri Mit) which is more than 700 years old and is the world’s largest Gold Buddha. It is a treasure of Thailand and Buddhism. Marble Temple is (Wat Ben Cha Ma Bo Phit) one of the most modern works of Thai Religious Architecture. After City tour drop at hotel. Evening free for shopping in Bangkok. Overnight comfortable stay in hotel."
                day5="Day 05 Bangkok - Departure"
                content5="After having delicious breakfast in hotel restaurant. Collect your last minutes memories and pack your luggage. Complete the checkout formalities (STD International checkout time is 11:00Hrs-12:00Hrs) on hotel reception and clear your dues (In room chargeable services & minibar bill) if any. Transfer to Airport, with the end of Memorable Trip with Shree Hari Tours & Travels."
                hide6="hide6"
                hide7="hide7"
                hide8="hide8"
                hide9="hide9"
                hide10="hide10"
                dl="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/itinarary%2Ftha.pdf?alt=media&token=081040bc-0e7b-4f70-abc1-8e807f2bad0f"
            />
        </div>
    )
}

export default Thailand;
