import React from 'react'
import PackageDetails from '../PackageDetails'

const andPD1 = () => {
    return (
        <div>
            <PackageDetails
                src="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/holidays%2Fandaman.jpg?alt=media&token=8fdc6f33-bc73-4169-8e47-09df4991e1b7"
                package="Exotic Andaman Tour 5 Nights and 6 Days"
                day1="Day 01 Port Blair Arrival"
                content1="On arrival at Port Blair Airport by flight, you will be received and transferred to your hotel. In the afternoon start a tour of places in and around Port Blair -covering the Carbyn Scow Beach & Cellular Jail. In the evening attend the enthralling Sound and Light Show at Cellular Jail, where the heroic saga of the Indian freedom struggle is brought alive. Overnight stay at Hotel Note : The Museum is Open all 7 days except Public Holidays. If your trip falls on a public holiday, we will cover Museum and Sound & Light Show on the alternative days"
                day2="Day 02 Port Blair SS (Rose & North Bay Island)"
                content2="After Breakfast visit the first Administrative settlement of the Britishers in the Andaman   Islands,   Ross   Island.   Earlier,   said   as   the   Paris   of   the   past.   This island today has ruins of all the luxury the Britishers had during that time. Visit this amazing destination and move forward on a boat to visit the CoralIsland of Port Blair, North Bay Island. Infringe with rich marine life this islandis the other most visited destination in Andaman. Scuba Diving, Sea Walk, Snorkeling and Glass boat ride can be done here. Return back to Hotel fromthese famous destinations rest and relax. Overnight stay at hotel.(Rose Island will be  closed on Wednesdays.)"
                day3="Day 03 Port Blair to Havelock"
                content3="Depart to Havelock Island. The destination is rated as the best island in India.Depart from Port Blair to Havelock Island in a ferry. Upon reaching Havelock will  transfer  you   at   your  hotel.   Later  we   begin   our  journey   to  one  among Asia's   best   beach,   Radhanagar   Beach.   Explore   this   white   sand   beach   andbask in the beauty of this natural wonder. Post this we drop you back to your hotel and Overnight stay"
                day4="Day 04 Havelock to Neil (Elephanta Beach SS - Neil)"
                content4="After Breakfast check out from Hotel and Proceed for Elephantabach. Around 30 Minutes by boat, Elephant Beach flaunts one among the best reefs in Havelock Island. Upon reaching Elephant beach takes you to snorkel underwater where you explore the amazing reef in Elephant beach. Later we return back to the Havelock. Later board ferry to Neil. On arrival Check in at Hotel. Later Visit (if time Permits) Bharatpur beach and also visit to Howrah Bridge. Try to get a glimpse of sunset point at Sitapur beach. Over-night stay at Hotel"
                day5="Day 05 Neil to Port Blair"
                content5="After Breakfast, We return back from Neil Island to Port Blair by ferry. Upon reaching   Port   Blair   transfer   to   Hotel.   Rest   and   relax   and   later   go   for Shopping. Back to Hotel and overnight stay"
                day6="Day 06 Departure transfer"
                content6="Transfer to Airport / Railway station. Back to home with sweet memories of your tour."
                hide7="hide7"
                hide8="hide8"
                hide9="hide9"
                hide10="hide10"
                dl="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/itinarary%2Fa1.pdf?alt=media&token=5b4e9af8-0992-4eb8-acdb-4ba6813a4350"
            />
        </div>
    )
}

export default andPD1;
