import React from 'react'
import PackageDetails from '../PackageDetails'

const CST = () => {
    return (
        <div>
            <PackageDetails
                src="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/holidays%2Fcostalspander.jpg?alt=media&token=b9a1fb7b-d8bb-4714-b4d4-ec3e97399a1c"
                package="Costal Splendors Tour 4N/5D Package"
                day1="Day 01 Madurai"
                content1="The Athens of the East, Cradle of Tamil Culture, where creation of epics and edifices was encouraged. Visit Sri Meenakshi Temple, with its magnificent sculpture, soaring gopurams and hall of thousand pillars. Visit Thirumal Nayak Mahal, Mariamman Amman Tank.Overnight stay at the hotel."
                day2="Day 02 Rameswaram"
                content2="Rameswaram is one of the four most sacred Hindu Pilgrim centres. The Sri Ramanathaswamy temple ( of Lord Sree Rama )has magnificent sculptures and spectacular corridors. Overnight stay at the hotel. Note:(Big Vehicle's (Bus) entry restricted to Rameswaram Town)"
                day3="Day 03 Kanniyakumari"
                content3="Located at the southern tip of peninsular India, it offers a unique experience of sunrise & sunset. Visit rock memorial dedicated to Swami Vivekananda, the Gandhi Memorial, Suchidram Temple with exquisite sculpture & musical pillars dedicated to Brahma, Vishnu &Shiva.Overnight stay at the hotel."
                day4="Day 04 Kovalam"
                content4="Kovalam - breathtakingly beautiful - a haven of peace and tranquillity - the idyllic tourist destination in God's own country. Kovalam offers an excellent diversity with Kovalam beach to suit all desires and occasions. Visit light house beach and Hawah beach. Overnight stay at the hotel."
                day5="Day 05 Kovalam SS"
                content5="Proceed for sightseeing at Trivandrum. Visit Sree Padmanabhaswamy temple (16 km) which is 16th century temple dedicated to Lord Vishnu is the most impressive landmark of the city. Visit Trivandrum Zoo, Sree Chitra Art Gallery,Napier Museum -(16km) (Closed on Mondays, Wednesday, Govt Holidays, Visiting time :1.00 PM to 4.45 PM), Science and technology museum (Closed on Mondays) etc. Enjoy sun set in the evening relaxing on the beach.Overnight stay at the hotel.Note: Distance mentioned in KM is from the City Centre to the sight seeing spot."
                day6="Day 06 Departure transfer- Trivandrum"
                content6="Transfer to Airport / Railway station. Back to home with sweet memories of your tour."
                hide7="hide7"
                hide8="hide8"
                hide9="hide9"
                hide10="hide10"
                dl="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/itinarary%2Fcst.pdf?alt=media&token=0ec43074-f59a-4e62-92c7-b9664fbec103"
            />
        </div>
    )
}

export default CST;
