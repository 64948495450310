import React from 'react'
import PackageDetails from '../PackageDetails'

const SouPD2 = () => {
    return (
        <div>
            <PackageDetails
                src="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/holidays%2Fsou2.jpg?alt=media&token=ec9239cd-424d-4cf7-a808-6940d263a1fe"
                package="Statue of Unity with Tent stay"
                content1="Departure From Adajan, Surat every Friday and Sunday moring at 06:00 am."
                content2="Reach Gumandev at around 09:00 am, Halt for temple visit."
                content3="Check in the Tents. After that proceed to Statue Of Unity, Entry at 02:00 pm. Here you will visit Valley Of Flowers, Sardar Sarovar Dam, Audio Visual Show, Mueseum, Viewing Gallery, Laser Show."
                content4="After finishing Laser Show at around 08:00pm, back to the tents."
                content5="Check out from the tents. And proceed to Nilkanth Dham Temple, Poicha."
                content6="Free time till 07:00 pm. Proceed back to Surat, with end of memorable tour with Shree Hari Travels."
                hide7="hide7"
                hide8="hide8"
                hide9="hide9"
                hide10="hide10"
                dl="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/itinarary%2Fsu2.pdf?alt=media&token=dd64c22f-c061-4313-8f02-86580af45f6f"
            />
        </div>
    )
}

export default SouPD2;
