import React from 'react'
import '../../../sass/CheckList.scss'
import Button from '../../Layout/Btn'

const gbr = () => {
    return (
        <>
            <div className="title">
                <h3>UK Tourist Visa Check List</h3>
            </div>
            <div className="checklist">
                <div className="list">
                    <h4>For Individuals</h4>
                    <ol>
                        <li>Passport: original passport with validity of minimum six months and minimum two blank pages for visa stamp. Attach all your old passports (if any)</li>
                        <li>Photo specification: two recent passport size colored photographs with matt or semi matt finish, 60%-80% facecoverage, white background and without border (size: 35mm x 45mm) without spectacles.<ul><li>Please note : photographs should not be more than three months old, scanned / stapled and should not be used in any of the previous visa's.</li></ul></li>
                        <li>Pan card.</li>
                        <li>3 yrs Income Tax Return (CA certified or true copy).</li>
                        <li>6 months bank statement with bank stamp & sign.</li>
                        <li>Savings ( LIC/ KVP/ NSC/ FD/ Any other savings).</li>
                        <li>Property papers, Light/ Maintainance /Telephone bill.</li>
                        <li>Net worth certificate form chartered accountant (if possible).</li>
                        <li>Office proof - Light/Maintainance/Telephone bill.</li>
                        <li>Surrender Value Certificate.</li>
                        <li>03 company letter-head (stamped &signed).</li>
                    </ol>
                </div>
                <div className="list">
                    <h4>For Partnership Firm</h4>
                    <ol>
                        <li>Pan Card</li>
                        <li>Last 03 Years Income Tax Returns (CA certified or true copy)</li>
                        <li>Updated Last 06 months Bank Statement with Bank stamp & Sign</li>
                        <li>Partnership Deed</li>
                    </ol>
                </div>
                <div className="list">
                    <h4>For Children</h4>
                    <ol>
                        <li>School Bonafide certificate</li>
                        <li>School ID Card</li>
                        <li>School leaving certificate ( If applicable )</li>
                        <li>Birth certificate</li>
                    </ol>
                </div>
                <div className="list">
                    <h4>For Sponsor</h4>
                    <ol>
                        <li>Passport Copy / Visa Copy</li>
                        <li>Sponsor Letter / Invitation Letter</li>
                        <li>Last 06 months Bank Statement / balance certificate</li>
                        <li>Last 03 years Income Tax Paper</li>
                        <li>Latest Utility Bill Copy</li>
                    </ol>
                </div>
            </div>
            <div className="cl-btn">
                <div className="pd-btn">
                    <Button download="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/downloads%2FUK%20VISA%20DETAILS%20FORM%20(1).docx?alt=media&token=e2c8efbf-5700-4740-8f6f-1de8715c7a05" />
                </div>
            </div>
        </>
    )
}

export default gbr;