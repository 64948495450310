import React from 'react'
import Flag from './Flag'

const Visa = () => {
    return (
        <div>
            <div className="title">
                <h3>Visa</h3>
            </div>
            <Flag />
        </div>
    )
}

export default Visa;