import React from 'react'
import '../../../sass/CheckList.scss'
import Button from '../../Layout/Btn'

const bgr = () => {
    return (
        <>
            <div className="title">
                <h3>Bulgaria Visa check List (Tourist)</h3>
            </div>
            <div className="checklist">
                <div className="list">
                    <h4>Personal</h4>
                    <ol>
                        <li>All Passports</li>
                        <li>02 New Photographs ( 35x45, 80% face, matte finish with white background, Without spectacles)</li>
                        <li>Copy of  Pan Card</li>
                        <li>Last 03 Years Income Tax Returns</li>
                        <li>Updated Bank Statement for the Last 6 Months with Bank stamp & sign (Min Bal. 1,50,000 Per Person)</li>
                        <li>Returned Air Ticket</li>
                        <li>Travel Insurance</li>
                        <li>Hotel confirmation</li>
                        <li>Returned Air Tickets</li>
                    </ol>
                </div>
                <div className="list">
                    <h4>For Company</h4>
                    <ol>
                        <li>Copy of Pan Card</li>
                        <li>Last 03 Years Income Tax Returns</li>
                        <li>Updated Bank Statement for the Last 6 Months with Bank stamp & sign</li>
                        <li>Company Proof in English (Registration//Partnership Deed//Memorandum)</li>
                        <li>03 Letter Head ( with stamp & sign)</li>
                        <li>Office Proof ( Light/phone Bill)</li>
                    </ol>
                </div>
                <div className="list">
                    <h4>For Employee</h4>
                    <ol>
                        <li>Copy of  Pan Card</li>
                        <li>Last 03 Months Salary Slip on Company Letter Head with Stamp & Sign</li>
                        <li>Updated Bank Statement for the Last 6 Months with Bank stamp & sign (Salary Account)</li>
                        <li>Leave Letter on Company Letter Head with Stamp & Sign</li>
                        <li>Office Id Proof</li>
                    </ol>
                </div>
                <div className="list">
                    <h4>For Student</h4>
                    <ol>
                        <li>School ID Card Copy</li>
                        <li>NOC Letter from School / College (Must Mentioned Travel Date)</li>
                    </ol>
                </div>
                <div className="list">
                    <h4>For Sponsor</h4>
                    <ol>
                        <li>Passport Copy</li>
                        <li>Valid Visa Copy</li>
                        <li>Original Sponsor Letter/Invitation Letter</li>
                        <li>Last 06 months Banks Statement</li>
                        <li>Last 03 years Income Tax Paper</li>
                        <li>Copy of Utility Bill / Residence Proof( Light Bill / Phone Bill )</li>
                    </ol>
                </div>
            </div>
            <div className="cl-btn">
                <div className="pd-btn">
                    <Button download="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/downloads%2F3.pdf?alt=media&token=22ef9127-ceda-48a1-9d40-b9578b21cc3d" />
                </div>
            </div>
        </>
    )
}

export default bgr;