import React from 'react'
import '../../../sass/CheckList.scss'
import Button from '../../Layout/Btn'

const chn = () => {
    return (
        <>
            <div className="title">
                <h3>China Visa Check List (Tourist)</h3>
            </div>
            <div className="checklist">
                <div className="list">
                    <ol>
                        <li>Old & NewPassport</li>
                        <li>02 Photographs ( 33mm x 48mm, 80% Face with white back ground on Glossy Paper)</li>
                        <li>Copy of Pan Card</li>
                        <li>06 Months Updated Bank Statement with Bank Stamp & Sign (per pax Mini.1,50,000)</li>
                        <li>Return Air Tickets</li>
                        <li>Hotel Confirmation</li>
                        <li>Day to Day Itinerary</li>
                        <li>02 Letter Head With Stamp & Sign</li>
                    </ol>
                </div>
            </div>
            <div className="cl-btn">
                <div className="pd-btn">
                    <Button download="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/downloads%2F4.pdf?alt=media&token=e2b5a8fc-54f6-4bc7-8c9b-c3c0d23eb6ef" />
                </div>
            </div>
        </>
    )
}

export default chn;