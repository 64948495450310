import React from 'react'
import { Link } from 'react-router-dom'
import '../../sass/Button.scss'

const Btn = props => {
    return (
        <div className="button-grp">
            <Link className="button" to='.'>+91 75675 37173</Link>
            <a className="button" href={props.download} target="_blank" rel="noopener noreferrer" download>Download</a>
            <Link className="button" to='/form'>Get a Quote</Link>
        </div>
    )
}

export default Btn;