import React from 'react'
import PackageDetails from '../PackageDetails'

const GoaPD2 = () => {
    return (
        <div>
            <PackageDetails
                src="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/holidays%2Fgoa2.jpg?alt=media&token=b65ebd9c-95a2-4f78-860c-364ae0f0c528"
                package="GOA 4N/5D Package"
                day1="Day 01"
                content1="Arrival at Goa(Thivim Railway station/Airport). Meet our representative and transfer to our hotel. After refreshments, full day at leisure. Overnight stay at hotel."
                day2="Day 02"
                content2="After Breakfast, We will head towards North Goa Sightseeing, visit various places like Anjuna Beach, Vagator Beach, Baga Beach, Siquerim Beach. Later visit to Fort Aguada, Calangute Beach. Overnight Stay at Hotel."
                day3="Day 03"
                content3="Early morning departure today, visit Dudhsagar Falls. On the way back visit Spice village, a place where you can buy various goan spices. Overnight stay at hotel."
                day4="Day 04"
                content4="After Breakfast, we will proceed to South Goa Sightseeing, visit Old Goa Churches, Mangeshi Temple, Dona Paula, Miramar Beach. Later time for Shopping. Boat Cruise in the evening(Optional tour) / Casino (Optional). Overnight Stay at Hotel."
                day5="Day 05"
                content5="After Breakfast, Check out from hotel. Transfer to Airport/Thivim Railway Station, with the end of Memorable Trip with Shree Hari Tours & Travels."
                hide6="hide6"
                hide7="hide7"
                hide8="hide8"
                hide9="hide9"
                hide10="hide10"
                dl="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/itinarary%2Fg2.pdf?alt=media&token=5920cf9d-0fe6-4005-adef-db6b29ad91bf"
            />
        </div>
    )
}

export default GoaPD2;
