import React from 'react'
import '../../../sass/CheckList.scss'
import Button from '../../Layout/Btn'

const schengen = () => {
    return (
        <>
            <div className="title">
                <h3>Visa Check List</h3>
            </div>
            <div className="checklist">
                <div className="list">
                    <h4>For Individuals</h4>
                    <ol>
                        <li>All Passports</li>
                        <li>02 New Photographs ( 35x45, 80% face, matte finish with white background)</li>
                        <li>Copy of  Pan Card</li>
                        <li>Last 03 Years Income Tax Returns</li>
                        <li>Updated Personal Bank Statement for the Last 6 Months with Bank stamp & sign</li>
                        <li>Returned Air Ticket</li>
                        <li>Travel Insurance</li>
                        <li>Hotel confirmation</li>
                        <li>Residence Proof</li>
                    </ol>
                </div>
                <div className="list">
                    <h4>For Company</h4>
                    <ol>
                        <li>Copy of  Pan Card</li>
                        <li>Last 03 Years Income Tax Returns</li>
                        <li>Updated Bank Statement for the Last 6 Months with Bank stamp & sign</li>
                        <li>Company Proof in English (Registration//Partnership Deed//Memorandum)</li>
                        <li>03 Letter Head ( with stamp & sign)</li>
                        <li>Office Address Proof</li>
                    </ol>
                </div>
                <div className="list">
                    <h4>For Employee</h4>
                    <ol>
                        <li>Copy of  Pan Card</li>
                        <li>Last 03 Months Salary Slip on Company Letter Head with Stamp & Sign</li>
                        <li>Updated Bank Statement for the Last 6 Months with Bank stamp & sign (Salary Account)</li>
                        <li>Leave Letter on Company Letter Head with Stamp & Sign</li>
                        <li>Office Id Proof</li>
                    </ol>
                </div>
                <div className="list">
                    <h4>For Sponsor</h4>
                    <ol>
                        <li>Passport Copy</li>
                        <li>Valid Visa Copy</li>
                        <li>Original Sponsor Letter/Invitation Letter</li>
                        <li>Last 06 months Banks Statement</li>
                        <li>Last 03 years Income Tax Paper</li>
                        <li>Latest Utility Bill</li>
                    </ol>
                </div>
            </div>
            <div className="cl-btn">
                <div className="pd-btn">
                    <Button download="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/downloads%2FShree%20Hari%20Tours%20%26%20Travels2.pdf?alt=media&token=770bed64-2b12-4b08-9d30-6b8f4c711247" />
                </div>
            </div>
        </>
    )
}

export default schengen;