import React from 'react'
import '../../../sass/CheckList.scss'
import Button from '../../Layout/Btn'

const isr = () => {
    return (
        <>
            <div className="title">
                <h3>Israel Visa check List (Tourist)</h3>
            </div>
            <div className="checklist">
                <div className="list">
                    <h4>Personal</h4>
                    <ol>
                        <li>Old & New Passport</li>
                        <li>02 Recent Photographs(50mm x 50mm, 70% face,matte finish,white background Without spectacles)</li>
                        <li>Copy of Pan Card</li>
                        <li>Copy of Adhar Card</li>
                        <li>Updated Last 03 Months Savings Bank Statement with Bank Stamp & Sign on A4 Size Paper (Min. Bal. 1,50,000 per person)</li>
                        <li>Returned Ticket</li>
                        <li>Travel Insurance</li>
                        <li>Hotel confirmation with Day to Day Itinerary</li>
                        <li>03 Company Letter Head with stamp & sign</li>
                        <li>Company Proof in English (Registration//Partnership Deed//Memorandum)</li>
                    </ol>
                </div>
                <div className="list">
                    <h4>For Employee</h4>
                    <ol>
                        <li>Copy of  Pan Card</li>
                        <li>Last 03 Months Salary Slip on Company Letter Head with Stamp & Sign</li>
                        <li>Updated Bank Statement for the Last 6 Months with Bank stamp & sign (Salary Account)</li>
                        <li>NOC Letter on Company Letter Head with stamp & Sign</li>
                        <li>Employment Id Proof</li>
                    </ol>
                </div>
                <div style={{ padding: '10px' }}>
                    <p><b>NOTE: </b>Colour Xerox of passport copy 1st & last page on single page only (Old & New).</p>
                </div>
            </div>
            <div className="cl-btn">
                <div className="pd-btn">
                    <Button download="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/downloads%2F9.pdf?alt=media&token=633abfa5-74be-4787-a702-6cf2c2901772" />
                </div>
            </div>
        </>
    )
}

export default isr;
