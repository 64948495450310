import React from 'react'
import firebase from 'firebase/app'
import 'firebase/app'
import 'firebase/firestore'
import NumberFormat from 'react-number-format'
import '../../sass/Contact.scss'

class Contact extends React.Component {
    state = {
        name: '',
        number: '',
        email: '',
        message: ''
    }

    handleSubmit = e => {
        e.preventDefault();

        const db = firebase.firestore();
        
        db.collection("contact").add({
            name: this.state.name,
            number: this.state.number,
            email: this.state.email,
            message: this.state.message
        });
        
        //console.log("data..")
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    render() {
        return (
            <div className="contact">
                <div className="title">
                    <h3>Contact Us</h3>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <form onSubmit={this.handleSubmit} className="form">
                            <div className="form-grp">
                                <label><i className="fa fa-user icon"></i>Name</label>
                                <input onChange={this.handleChange} name="name" type="text" placeholder="Full Name" required />
                            </div>
                            <div className="form-grp">
                                <label><i className="fa fa-phone icon"></i>Number</label>
                                <NumberFormat prefix={"+91"} format="+91 ###-###-####" onChange={this.handleChange} name="number" placeholder="Enter your Phone Number" required />
                                <span>We will contact you on this number</span>
                            </div>
                            <div className="form-grp">
                                <label><i className="fa fa-at icon"></i>Email</label>
                                <input onChange={this.handleChange} name="email" type="email" placeholder="yourmail@example.com" required />
                            </div>
                            <div className="form-grp">
                                <label><i className="fa fa-envelope icon"></i>Message</label>
                                <textarea onChange={this.handleChange} name="message" placeholder="Your message here...." required ></textarea>
                            </div>
                            <div className='form-grp'>
                                <button onClick={() => { window.confirm('Message sent successfully!!!') }}>Send <i className="fa fa-send icon"></i></button>
                            </div>
                        </form>
                    </div>
                    <div className="col-md-6 address">
                        <div className="title">
                            <h3><b>Address</b></h3>
                            <p>Silver Plaza Complex, UF-7, Rander Rd, opp. RUPALI CINEMA, Palanpur Patia, Surat, Gujarat 395009</p>
                        </div>
                        <div className="title">
                            <h3><b>Call Now</b></h3>
                            <small style={{  }}> Click any number to make a call</small>
                            <p style={{ fontSize: '1.5rem' }}><b><a style={{color: '#000'}} href="tel:75678 37173">+91 75678 37173</a> <br /> <a style={{color: '#000'}} href="tel:94261 07637">+91 94261 07637</a> <br /> <a style={{color: '#000'}} href="tel:70690 29095">+91 70690 29095</a></b></p>
                        </div>
                        <div className="title">
                            <h3><b>Mail</b></h3>
                            <p style={{ fontSize: '1.2rem' }}><a href="mailto:shreeharitravelssurat@gmail.com"> shreeharitravelssurat@gmail.com</a></p>
                        </div>
                    </div>
                </div>
                <iframe className="map" title="map" frameBorder="0" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d238259.55137190045!2d72.74592239848846!3d21.080429576278497!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04de20b179c63%3A0xe88222e921a84a75!2sShree%20Hari%20Travels!5e0!3m2!1sen!2sin!4v1612186543852!5m2!1sen!2sin" allowFullScreen></iframe>
            </div>
        )
    }
}

export default Contact;