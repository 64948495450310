import React from 'react'
import firebase from 'firebase/app'
import 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import NumberFormat from 'react-number-format'
import fire from '../../config/fire'
import '../../sass/Form.scss'

class Form extends React.Component {
    state = {
        name: '',
        number: '',
        email: '',
    }
    componentDidMount() {
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
        'size': 'invisible',
        'callback': function (response) {
            // reCAPTCHA solved, allow signInWithPhoneNumber.
            this.onClick();
        }
    });
    }

    onClick = (e) => {
        e.preventDefault();

        const phoneNumber = this.state.number;
        //const name = this.state.name;
        const appVerifier = window.recaptchaVerifier;
        fire
            .auth()
            .signInWithPhoneNumber(phoneNumber, appVerifier)
            .then(confirmationResult => {
                // success
                //console.log(confirmationResult)
                window.confirmationResult = confirmationResult; 
                
                const verificationCode = window.prompt('Please enter the OTP code that was sent to your mobile device.');
                
                confirmationResult.confirm(verificationCode).then(function (result) {
                    // User signed in successfully.
                    // ...
                    //console.log("loged in...")

                    window.confirm('Thanks! You will be contacted shortly');
                }).then(
                    () => {
                        const db = firebase.firestore();

                        db.collection("packages").add({
                            name: this.state.name,
                            number: this.state.number,
                            email: this.state.email
                        });  
                    }
                ).catch(function (error) {
                    // User couldn't sign in (bad verification code?)
                    // ...
                    //console.log(error)
                    window.confirm('Invalid verification code please try again');
                });

            })
            .catch(error => {
                // error
            }); 
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
        //console.log(e.target.value)
    }

    render() {
        return (
            <>
                <div className="form">
                    <div className="title">
                        <h3>Thank You!</h3>
                    </div>
                    <div className="f-from">
                        <form>
                            <div className="f-grp">
                                <label>Name</label>
                                <input type="text" onChange={this.handleChange} name="name" placeholder="Your Full Name" required />
                            </div>
                            <div className="f-grp">
                                <label>Number</label>
                                <NumberFormat prefix={"+91"} format="+91 #####-#####" onChange={this.handleChange} name="number" placeholder="Enter your Phone Number" required />
                                <span>OTP will be sent on this Number</span>
                            </div>
                            <div className="f-grp">
                                <label>E-Mail</label>
                                <input type="email" onChange={this.handleChange} name="email" placeholder="yourmail@example.com" required />
                            </div>
                            <div id="recaptcha-container"></div>
                            <div className="f-grp">
                                <input className="button" type="submit" name="Submit" value="Submit" onClick={this.onClick} />
                            </div>
                        </form>
                    </div>
                </div>
            </>
        )
    }
}

export default Form