import React from 'react'
import '../../../sass/CheckList.scss'
import Button from '../../Layout/Btn'

const rus = () => {
    return (
        <>
            <div className="title">
                <h3>Russia Visa Check List</h3>
            </div>
            <div className="checklist">
                <div className="list">
                    <ol>
                        <li>All Passports</li>
                        <li>03 Photographs (35x45, 80% face on matte finish paper with white background)</li>
                        <li>Original Hotel Confirmation from Russia approved by ministry of Russia</li>
                        <li>02 Company Letter Head with stamp & Sign</li>
                        <li>Return Flight Tickets</li>
                    </ol>
                </div>
            </div>
            <div className="cl-btn">
                <div className="pd-btn">
                    <Button download="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/downloads%2F17.pdf?alt=media&token=fe0e2e83-a532-4656-b8be-3346baaccd40" />
                </div>
            </div>
        </>
    )
}

export default rus;