import React from 'react'
import '../../../sass/CheckList.scss'
import Button from '../../Layout/Btn'

const egy = () => {
    return (
        <>
            <div className="title">
                <h3>Egypt Visa Checklist (Tourist)</h3>
            </div>
            <div className="checklist">
                <div className="list">
                    <h4>For Individuals</h4>
                    <ol>
                        <li>Old & New Passport</li>
                        <li>02 New Photographs (35x45, 80% face on matte finish paper with white background, without spectacles)</li>
                        <li>Copy of Pan Card</li>
                        <li>Updated 06 Months Savings Bank Statement with Bank stamp & sign on every page (Min. Bal Per Person 1,00,000)</li>
                        <li>Return Air Tickets</li>
                        <li>Hotel Confirmation</li>
                        <li>Group Letter</li>
                        <li>02 Blank Letter Head With Stamp & Sign</li>
                    </ol>
                </div>
            </div>
            <div className="cl-btn">
                <div className="pd-btn">
                    <Button download="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/downloads%2F6.pdf?alt=media&token=4a209ea2-a792-442b-a4be-c1582db0aeee" />
                </div>
            </div>
        </>
    )
}

export default egy;