import React from 'react'
import { Link } from 'react-router-dom'
// import Carousel from 'react-bootstrap/Carousel'
import Card from '../Layout/Card'
import Review from '../Layout/Reviews'
import Contact from '../Layout/Contact'
import '../../sass/Homepage.scss'
// import MyAlert from '../Layout/Alert'

const Homepage = () => {
    return (
        <div className="homepage">
            {/* <MyAlert /> */}
            {/* <Carousels /> */}
            <Services />
            <Cards />
            <Parallax />
            <Reviews />
            <Contact />
        </div>
    )
}

// const Carousels = () => {
//     return (
//         <div className="Carousels">
//             <Carousel> 
//                 <Carousel.Item>
//                     <img
//                         className="d-block"
//                         src="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/ads%2FIMG-20200303-WA0007.jpg?alt=media&token=5edb271a-ded0-4197-be40-0dbf9d172a1c"
//                         alt="ads"
//                     />
//                     <Carousel.Caption>
//                     </Carousel.Caption>
//                 </Carousel.Item> 
//                 <Carousel.Item>
//                     <img
//                         className="d-block"
//                         src="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/ads%2FIMG-20200123-WA0016.jpg?alt=media&token=7d916398-98f5-4fa7-a4b8-574097b970b5"
//                         alt="ads"
//                     />
//                     <Carousel.Caption>
//                     </Carousel.Caption>
//                 </Carousel.Item>   
//             </Carousel>
//         </div>
//     );
// }

const Services = () => {
    return (
        <div className='service'>
            <h2>Our Service</h2>
            <div className="icons">
                <div className="icon">
                    <Link to='/flights' className="link">
                        <img src="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/icons%2Ficon1.png?alt=media&token=a693091b-d873-4853-ba53-201218688ed5" alt='' />
                        <p>Flights</p>
                    </Link>
                </div>
                <div className="icon">
                    <Link to='/hotel' className="link">
                        <img src="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/icons%2Ficon2.png?alt=media&token=ba9a11c4-5541-4c99-b8e6-c782789d9388" alt='' />
                        <p>Hotels</p>
                    </Link>
                </div>
                <div className="icon">
                    <Link to='/holidays' className="link">
                        <img src="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/icons%2Ficon3.png?alt=media&token=108897f8-8ff6-4fe6-beeb-99a541e02a02" alt='' />
                        <p>Holidays</p>
                    </Link>
                </div>
            </div>
            <div className="icons">
                <div className="icon">
                    <Link to='/visa' className="link">
                        <img src="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/icons%2Ficon4.png?alt=media&token=04be21c2-21f4-4a9a-86c0-903ef8cf2b40" alt='' />
                        <p>Visa</p>
                    </Link>
                </div>
                <div className="icon">
                    <Link to='/bus' className="link">
                        <img src="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/icons%2Ficon5.png?alt=media&token=0c684ecd-9bd7-4896-aa6a-1a121299ae59" alt='' />
                        <p>Bus</p>
                    </Link>
                </div>
                <div className="icon">
                    <Link to='/others' className="link">
                        <img src="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/icons%2Ficon6.png?alt=media&token=9518e193-fedc-4150-8693-edd224f03343" alt='' />
                        <p>Other's</p>
                    </Link>
                </div>
            </div>
        </div>
    )
}

const Cards = () => {
    return (
        <>
            <div className="title">
                <p>HOT RIGHT NOW</p>
                <h3>Popular Tours</h3>
            </div>
            <div className="parallax1">
                <div className="row">
                    <div className="col-md-9">
                        <div className="card-grp" style={{ justifyContent: 'flex-start' }}>
                            <Card
                                src="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/holidays%2Fbangkok.jpg?alt=media&token=9f910341-ae1b-457e-9984-8b83d870193f"
                                title="Thailand"
                                button="Explore Now"
                                href="/thailand"
                            />
                            <Card
                                src="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/holidays%2Feurope.jpg?alt=media&token=75a0aeb1-e507-4f7d-bd83-8f4a9ed5da86"
                                title="Europe"
                                button="Explore Now"
                                href="/eur"
                            />
                            <Card
                                src="https://m.economictimes.com/photo/48933538.cms"
                                title="Andamans"
                                button="Explore Now"
                                href="/and"
                            /> 
                        </div>
                    </div>
                    <div className="col-md-3">
                        <p>Feature Tours</p>
                        <h2>Wonderful World</h2>
                    </div>
                </div>
            </div>
        </>
    )
}

const Parallax = () => {
    return (
        <>
            <div className="title">
                <p>Trending Now</p>
                <h3>Domestic Tours</h3>
            </div>
            <div className="parallax">
                <div className="row">
                    <div className="col-md-3">
                        <p>Feature Tours</p>
                        <h2>Discover India</h2>
                    </div>
                    <div className="col-md-9">
                        <div className="card-grp" style={{ justifyContent: 'flex-start' }}>
                            <Card
                                src="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/holidays%2Fsou.webp?alt=media&token=48c2ea69-f8b9-4511-89fd-01873becd577"
                                title="Statue of Unity"
                                button="Explore Now"
                                href="/sou"
                            />
                            <Card
                                src="https://images.unsplash.com/photo-1512343879784-a960bf40e7f2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1000&q=80"
                                title="Goa"
                                button="Explore Now"
                                href='/goa'
                            />
                            <Card
                                src="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/holidays%2Fhimachal.webp?alt=media&token=c9db03a1-116d-40cd-a40d-77ab8900097c"
                                title="Himachal"
                                button="Explore Now"
                                href="/hima"
                            />
                            <Card
                                src="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/holidays%2FKerala2.jpg?alt=media&token=c20ce810-6660-47fb-8cb1-2cbe849cc259"
                                title="Kerala"
                                button="Explore Now"
                                href="/kerala"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const Reviews = () => {
    return (
        <div className="reviews-grp">
            <div className="title">
                <p>What our Customer Say's</p>
                <h3>Customer Reviews</h3>
            </div>
            <div className="card-grp review-grp" style={{ justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                <Review
                    name="Tony Gandhi"
                    star1="checked"
                    star2="checked"
                    star3="checked"
                    star4="checked"
                    star5="checked"
                    comment="It was nice and very beautiful trip organized by Mr. shaishav for goa."
                />
                <Review
                    name="Yogesh"
                    star1="checked"
                    star2="checked"
                    star3="checked"
                    star4="checked"
                    comment="Average "
                />
                <Review
                    name="Shruti Patel"
                    star1="checked"
                    star2="checked"
                    star3="checked"
                    star4="checked"
                    star5="checked"
                    comment=""
                />
                <Review
                    name="ish narang"
                    star1="checked"
                    star2="checked"
                    star3="checked"
                    star4="checked"
                    star5="checked"
                    comment=""
                />
                <Review
                    name="Ahir Yogesh"
                    star1="checked"
                    star2="checked"
                    star3="checked"
                    star4="checked"
                    star5="checked"
                    comment="Service is very very good 👌👌👌👍👍👍"
                />
                <Review
                    name="Nency Rami"
                    star1="checked"
                    star2="checked"
                    star3="checked"
                    star4="checked"
                    star5="checked"
                    comment="i had a very good experience while on a tour. everything was  well done. management was excellent by  shri hari travelers. educationtrip was successfully completed without any errors. hotel rooms facility, foods , traveling service were well done.👍👍👍"
                />
                <Review
                    name="Dave Bhargav"
                    star1="checked"
                    star2="checked"
                    star3="checked"
                    star4="checked"
                    star5="checked"
                    comment="Very nice experience with shaishav bhai & team. Delicious food,clean & comfortable bus. Aur kya chahiye???? 😂😂😂"
                />
                <Review
                    name="Viral Shah"
                    star1="checked"
                    star2="checked"
                    star3="checked"
                    star4="checked"
                    star5="checked"
                    comment="Statue of unity..one day family picnic tour is becoming good memories to all of us as well managed by Sh Hari Tours...Thanks to Shaishav & Hemantbhai...😊"
                />
                <Review
                    name="Maitry Patel"
                    star1="checked"
                    star2="checked"
                    star3="checked"
                    star4="checked"
                    star5="checked"
                    comment="I experienced of grand tour...ND work of the shree Hari is very effective ND also journy is also comfortable .....no any complications .....we all students are satisfied with your good work .And affords..👍👍thank you very much from students of government college of nursing ...new civil hospital Surat 😃"
                />
                <Review
                    name="Kalpesh Jadav"
                    star1="checked"
                    star2="checked"
                    star3="checked"
                    star4="checked"
                    star5="checked"
                    comment="Bus is very comfortable, Fantastic food ,accommodation is Good 👌👌👌"
                />
                <Review
                    name="Khyati Sheth"
                    star1="checked"
                    star2="checked"
                    star3="checked"
                    star4="checked"
                    star5="checked"
                    comment="Awesome tour managed by shree hari travel...well organized...food was delicious , rooms were nice ..bus also superb..Shree hari travel is really good..Keep it up your best"
                />
                <Review
                    name="bimal darji"
                    star1="checked"
                    star2="checked"
                    star3="checked"
                    star4="checked"
                    star5="checked"
                    comment="Statue of unity one day picnic full enjoy and nice Arrangement"
                />
                <Review
                    name="Nikki Pandya"
                    star1="checked"
                    star2="checked"
                    star3="checked"
                    star4="checked"
                    comment="The trip we made with Shri Hari Tours and Travels was really well organised and great care of the client’s comfort was taken. The tour manager stays in contact whenever the client needs to communicate something and that’s the most nice thing. Keep up the good work!"
                />
                <Review
                    name="kakoli chatterjee"
                    star1="checked"
                    star2="checked"
                    star3="checked"
                    star4="checked"
                    star5="checked"
                    comment="Awesome service and fulfilled all requirements asked for tour plan in 
                    ..time..Good food"
                />
                <Review
                    name="vinod vadhel"
                    star1="checked"
                    star2="checked"
                    star3="checked"
                    star4="checked"
                    comment="I feel amazing experience Organised by Shree Hari Tours & Travels.   Me and My friend's family Good enjoys Goa trip also Good Vegetarian food 👌👌👌and Hotel accommodation 👌👌👌with our concert (3 and 4 sharing Room's) one more Transportation also include 👌👌👌"
                />
                <Review
                    name="Jimisha Patel"
                    star1="checked"
                    star2="checked"
                    star3="checked"
                    star4="checked"
                    star5="checked"
                    comment="Lovely experience. Well planned trip . Food 😋👌. Looking forward to another trip 😊"
                />
                <Review
                    name="Meena Singhi"
                    star1="checked"
                    star2="checked"
                    star3="checked"
                    star4="checked"
                    star5="checked"
                    comment="It was an amazing experience with Shri Hari travels
                    Bus was so comfortable.... N management was well to do from pick up to drop  from breakfast to dinner 
                    If your are planning a vacation or a one day picnics contact them."
                />
                <Review
                    name="Jolly Pandya"
                    star1="checked"
                    star2="checked"
                    star3="checked"
                    star4="checked"
                    star5="checked"
                    comment="Your service gave us a wonderful experience..."
                />
                <Review
                    name="Dhruvkumar Patel"
                    star1="checked"
                    star2="checked"
                    star3="checked"
                    star4="checked"
                    star5="checked"
                    comment="Excellent visa service."
                />
                <Review
                    name="Mustakeem Shaikh"
                    star1="checked"
                    star2="checked"
                    star3="checked" 
                    comment="Biggest and experience travel agency"
                /> 
            </div>
        </div>
    )
}

export default Homepage;