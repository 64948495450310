import React from 'react'
import '../../../sass/CheckList.scss'
import Button from '../../Layout/Btn'

const geo = () => {
    return (
        <>
            <div className="title">
                <h3>GEORGIA VISA CHECK LIST (Tourist)</h3>
            </div>
            <div className="checklist">
                <div className="list">
                    <h4>For Individuals</h4>
                    <ol>
                        <li>Old & New Passport</li>
                        <li>02 New Photographs ( 35x45, 80% face, matte finish with white background)</li>
                        <li>Copy of Pan Card</li>
                        <li>Last 03 Years Income Tax Returns</li>
                        <li>Updated Last 03 Months  Bank Statement with Bank stamp & sign on each and every page ( per Person Balance required 1,50,000 to 2,00,000)</li>
                        <li>Returned Air Ticket</li>
                        <li>Travel Insurance</li>
                        <li>Hotel confirmation with Day to Day Itinerary</li>
                        <li>Residence Proof</li>
                    </ol>
                </div>
                <div className="list">
                    <h4>For Company</h4>
                    <ol>
                        <li>Copy of Pan Card</li>
                        <li>Last 03 Years Income Tax Returns</li>
                        <li>Updated Last 03 months Bank Statement with Bank stamp & Sign on each and every page</li>
                        <li>Company Proof in English (Registration//Partnership Deed//Memorandum)</li>
                        <li>03 Letter Head ( with stamp & sign)</li>
                    </ol>
                </div>
                <div className="list">
                    <h4>For Employee</h4>
                    <ol>
                        <li>Copy of  Pan Card</li>
                        <li>Last 03 Months Salary Slip on Company Letter Head with Stamp & Sign</li>
                        <li>Updated Bank Statement for the Last 6 Months with Bank stamp & sign (Salary Account)</li>
                        <li>Leave Letter on Company Letter Head with Stamp & Sign</li>
                        <li>Office Id Proof</li>
                    </ol>
                </div>
                <div className="list">
                    <h4>For Sponsor</h4>
                    <ol>
                        <li>Passport Copy / Valid Visa Copy</li>
                        <li>Original Sponsor Letter/Invitation Letter</li>
                        <li>Last 06 months Banks Statement</li>
                        <li>Last 03 years Income Tax Paper</li>
                        <li>Latest Utility Bill</li>
                    </ol>
                </div>
                <div style={{ padding: '10px' }}>
                    <p><b>NOTE: </b>If necessary, a visa issuing authority may request an additional document/ information, and/or invite the alien visa applicant and/or his/her representative for an interview to obtain additional information necessary to issue a visa.</p>
                </div>
            </div>
            <div className="cl-btn">
                <div className="pd-btn">
                    <Button download="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/downloads%2F7.pdf?alt=media&token=a0c76c9c-79b1-410a-881f-5fb405b74e27" />
                </div>
            </div>
        </>
    )
}

export default geo;
