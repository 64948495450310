import React from 'react'
import PackageDetails from '../PackageDetails'

const SouPD1 = () => {
    return (
        <div>
            <PackageDetails
                src="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/holidays%2Fsou.webp?alt=media&token=48c2ea69-f8b9-4511-89fd-01873becd577"
                package="Statue Of Unity Trip"
                content1="Departure at 5:00 am from Sarita Dairy, Honeypark Road, Adajan."
                content2="Pickup point: Old R.T.O. , Udhana Darjawa , Amisha Hotel and Hirabagh Circle."
                content3="Arrive at Gumandev Temple, at around 8:00 to 8:30."
                content4="Depart from Gumandev and Arrive at Nilkanth dham Poicha Temple at around 10:00 to 10:30."
                content5="Free time at leisure till 12:30 at Nilkanth dham,
                Depart from Nilkanth Dham Temple and arrive Statue of unity around 2:00"
                content6="Statue of unity hault from 2:00 to 9:00 Visit Viewing Gallery, Sardar Sarovar Dam, Valley Of Flowers, Museum, A/C Volvo bus charges and Audio Visual Show at night."
                content7="Depart from Statue of unity, arrive surat at around 12:00 to 12:30 at night."
                hide8="hide8"
                hide9="hide9"
                hide10="hide10"
                dl="https://firebasestorage.googleapis.com/v0/b/website-db3cf.appspot.com/o/itinarary%2Fsu1.pdf?alt=media&token=1ae8f0ad-86f7-474c-be5d-787b92c64fb2"
            />
        </div>
    )
}

export default SouPD1;
